import { Injectable } from '@angular/core';
import { FinanceDealStatus } from '@project/offtakers/finance-deal-status.enum';
import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { DateFilterComponent } from '@shared/ag-grid/component/date-filter/date-filter.component';
import { PortfolioGridStatusCellComponent } from '@shared/ag-grid/component/portfolio-grid-status-cell/portfolio-grid-status-cell.component';
import { GridState } from '@shared/ag-grid/gird-state';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class CiPortfolioListGridSettingService extends BaseAgGridSettingsService {
  private column = [];
  private statusKeys = {};

  public columnValues = [];
  public operatorValues = [];
  public typeValues = [
    'User Specified',
    'Fill from column'
  ];
  public currentGridState: GridState = new GridState();
  public initialGridState: GridState = new GridState();
  public defaultGridState: GridState = new GridState();
  public _gridStateStorageKey: string;
  public _gridApi: GridApi;
  public _columnApi: ColumnApi;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService) {
    super(_agGridServicesWrapperService);
    this.column = _agGridServicesWrapperService._translateService.instant('offtaker.project-set-portfolio.column');
    this.statusKeys = _agGridServicesWrapperService._translateService.instant('offtaker.project-set-portfolio.statuses');
  }

  public getGridOptions(includeProgram: boolean): GridOptions {
    return <GridOptions>{
      autoGroupColumnDef: {
        headerName: this.column['name']
      },
      columnDefs: this.getColumnSetting(includeProgram),
      components: {
        agDateInput: DateFilterComponent,
        portfolioGridStatusCellComponent: PortfolioGridStatusCellComponent,
      },
      context: {
        componentParent: this,
        currentLang: this.currentLang
      },
      enableGroupEdit: true,
      defaultColDef: {
        sortable: true,
        resizable: true
      },
      groupDisplayType: 'singleColumn',
      localeText: this.globalTranslations,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading Customers...</span>',
      rowHeight: 80,
      popupParent: document.querySelector('body'),
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['count', 'sum', 'avg'] // options: 'count', 'sum', 'min', 'max', 'avg'
            },
            align: 'right'
          }
        ]
      },
      suppressRowClickSelection: true,
    };
  }

  public getColumnSetting(includeProgram: boolean): any {
    let columns = [
      {
        headerClass: 'checkboxColumn',
        cellClass: 'checkboxCell',
        headerCheckboxSelection: true,
        checkboxSelection: (params) => {
          return params?.data ? true : false;
        },
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        width: 30,
        filter: false,
        resizable: false,
        suppressMenu: true,
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        pinned: 'left'
      },
      {
        headerName: this.column['name'],
        headerTooltip: this.column['name'],
        field: 'portfolioName',
        width: 350,
        rowGroup: true,
        hide: true,
        unSortIcon: true,
        enableRowGroup: true,
        enableValue: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          const id = params?.node?.allLeafChildren[0]?.data?.portfolioId;
          const name = params?.value;
          if (name) {
            return `<div style="display: flex; flex-direction: column;">
              <a class="hyperlink" href="#/oes/finance/finance-ci-portfolios/${ id }/project-overview?bc=${ name }">${ name }</a>
            </div>`;
          }
          return '';
        },
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['organization'],
        headerTooltip: this.column['organization'],
        field: 'developerOrganization.name',
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellRenderer: (params) => {
          if (params?.node?.allLeafChildren && params?.node?.allLeafChildren[0]?.data?.developerOrganization?.name) {
            const orgName = params?.node?.allLeafChildren[0]?.data?.developerOrganization?.name;
            return `<span style='font-weight: 500;'>${ orgName }</span>`;
          }
          return '';
        },
      },
      {
        headerName: this.column['program'],
        headerTooltip: this.column['program'],
        field: 'programName',
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellRenderer: (params) => {
          if (params?.node?.allLeafChildren && params?.node?.allLeafChildren[0]?.data?.programName) {
            const programName = params?.node?.allLeafChildren[0]?.data?.programName;
            return `<span style='font-weight: 500;'>${ programName }</span>`;
          } else if (params?.node?.allLeafChildren && !params?.node?.allLeafChildren[0]?.data?.programName) {
            return this.column['not-linked'];
          }
        },
      },
      {
        headerName: this.column['status'],
        headerTooltip: this.column['status'],
        field: 'status',
        width: 120,
        editable: (params) => {
          if (!params?.node?.allLeafChildren || !params?.node?.allLeafChildren[0]?.data?.programId) {
            return false;
          }
          if (params?.node?.allLeafChildren && params?.node?.allLeafChildren[0]?.data?.status) {
            return true;
          }
          return false;
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: (params) => {
          if (!params?.node?.allLeafChildren || !params?.node?.allLeafChildren[0]?.data?.programId) {
            return null;
          }
          if (params?.node?.allLeafChildren && params?.node?.allLeafChildren[0]?.data?.status) {
            return { values: Object.values(FinanceDealStatus) };
          }
          return '';
        },
        cellRenderer: (params) => {
          if (!params?.node?.allLeafChildren || !params?.node?.allLeafChildren[0]?.data?.programId) {
            return '-';
          }
          if (params?.node?.allLeafChildren && params?.node?.allLeafChildren[0]?.data?.status) {
            const status = params?.node?.allLeafChildren[0]?.data?.status;
            return `<span class="bullet ${ status }">
                      ${ this.statusKeys[status] }
                    </span>`;
          }
        },
        valueFormatter: (params) => {
          if (!params?.node?.allLeafChildren || !params?.node?.allLeafChildren[0]?.data?.programId) {
            return null;
          }
          return this.statusKeys[params.value];
        }
      },
      {
        headerName: this.column['total-sites'],
        headerTooltip: this.column['total-sites'],
        field: 'totalProjects',
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params?.node?.allLeafChildren?.length) {
            const totalSites = params.node.allLeafChildren.reduce((accum, childNode) => {
              const projects = childNode?.data?.totalProjects || 0;
              accum += projects;
              return accum;
            }, 0);
            return `<span style='font-weight: 500;'>${ totalSites } Sites</span>`;
          }
          return params.value;
        },
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['total-system-size'],
        headerTooltip: this.column['total-system-size'],
        field: 'totalProjects',
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params?.node?.allLeafChildren?.length) {
            const totalSystemSize = params.node.allLeafChildren.reduce((accum, childNode) => {
              const size = (childNode?.data?.totalProjects || 0) * (childNode?.data?.averageSystemSize || 0);
              accum += size;
              return accum;
            }, 0);
            return `<span style='font-weight: 500;'>${ totalSystemSize } kWp</span>`;
          }
          return (params?.data?.totalProjects || 0) * (params?.data?.averageSystemSize || 0);
        },
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['total-capex'],
        headerTooltip: this.column['total-capex'],
        field: 'totalCapex',
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params?.node?.allLeafChildren?.length) {
            const totalCapex = params.node.allLeafChildren.reduce((accum, childNode) => {
              const size = (childNode?.data?.totalCapex || 0);
              accum += size;
              return accum;
            }, 0);
            if (params.node?.allLeafChildren[0].data?.currencyType === 'USD') {
              return `<span style='font-weight: 500;'>$${ totalCapex }</span>`;
            } else {
              return `<span style='font-weight: 500;'>${ totalCapex } ${params.node?.allLeafChildren[0].data?.currencyType}</span>`;
            }
          }
          if (params.data?.currencyType === 'USD') {
            return '$' + (params?.value || 0);
          } else {
            return (params?.value || 0) + ' ' + params.data?.currencyType;
          }
        },
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
      }
    ];

    if (!includeProgram) {
      columns = columns.filter(column => {
        return column.field !== 'programName';
      });
    }

    return columns;
  }
}


