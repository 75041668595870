import { Injectable } from '@angular/core';
import { ColumnConfigFactory } from '@project/shared/grid/column-config-factory';
import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { CellButtonComponent } from '@shared/ag-grid/component/cell-button/cell-button.component';
import { GridState } from '@shared/ag-grid/gird-state';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class ManageOrganizationFinancialmodelsGridSettingService extends BaseAgGridSettingsService {
  private column = [];
  private types = [];

  public columnValues = [];
  public operatorValues = [];
  public typeValues = [
    'User Specified',
    'Fill from column'
  ];
  public currentGridState: GridState = new GridState();
  public initialGridState: GridState = new GridState();
  public defaultGridState: GridState = new GridState();
  public _gridStateStorageKey: string;
  public _gridApi: GridApi;
  public _columnApi: ColumnApi;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService) {
    super(_agGridServicesWrapperService);
    this.column = _agGridServicesWrapperService._translateService.instant('program.configuration.financial-model.column');
    this.types = _agGridServicesWrapperService._translateService.instant('program.configuration.financial-model');
  }

  public getGridOptions(orgId: string): GridOptions {
    return <GridOptions>{
      columnDefs: this.getColumnSetting(orgId),
      components: {
        cellButtonComponent: CellButtonComponent
      },
      context: {
        currentLang: this.currentLang
      },
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true,
        resizable: true,
        suppressSizeToFit: true,
        unSortIcon: true,
        enableValue: true,
        width: 200
      },
      groupDisplayType: 'singleColumn',
      localeText: this.globalTranslations,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading Financial Models...</span>',
      rowHeight: 80,
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['count', 'sum', 'avg'] // options: 'count', 'sum', 'min', 'max', 'avg'
            },
            align: 'right'
          }
        ]
      },
      suppressRowClickSelection: true,
    };
  }

  public getColumnSetting(orgId: string): any {
    return [
      {
        headerName: this.column['name'],
        headerTooltip: this.column['name'],
        field: 'name',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        flex: 1
      },
      {
        headerName: this.column['description'],
        headerTooltip: this.column['description'],
        field: 'description',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        flex: 2
      },
      {
        headerName: this.column['version'],
        headerTooltip: this.column['version'],
        field: 'modelVersion',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['type'],
        headerTooltip: this.column['type'],
        field: 'type',
        floatingFilter: false,
        floatingFilterComponentParams: {
          suppressFilterButton:true
        },
        cellRenderer: (params) => {
          if (params?.value) {
            return this.types[params.value];
          }
        }
      },
      {
        headerName: this.column['actions'],
        headerTooltip: this.column['actions'],
        field: 'actions',
        unSortIcon: true,
        enableValue: true,
        floatingFilter: false,
        floatingFilterComponentParams: {
          suppressFilterButton:true
        },
        sortable: false,
        suppressMovable: true,
        suppressSizeToFit: true,
        cellRenderer: 'cellButtonComponent',
        cellRendererParams: params => {
          if (params?.data?.organization?.id === orgId) {
            return { keys: ['edit'] };
          }
          return null;
        }
      }
    ];
  }
}
