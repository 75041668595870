import { Component, ElementRef, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'oes-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss'],
})
export class CollapsibleComponent implements OnChanges {
  @Input() index: number;
  @Input() title: TemplateRef<any>;
  @Input() headerIcon: string = '';
  @Input() headerIconBg: string = '';
  @Input() content: TemplateRef<any>;
  @Input() isOpen: boolean = false;
  @Input() onToggle: (index: number) => void;

  private COLLAPSED_HEIGHT: number = 76;
  private MARGIN_HEIGHT: number = 18;

  @ViewChild('contentContainer', { static: true }) contentContainer: ElementRef;
  @ViewChild('details', { static: true }) details: ElementRef;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen) {
      const detailsEl = this.details.nativeElement;
      const contentEl = this.contentContainer.nativeElement;
      if (this.isOpen) {
        requestAnimationFrame(() => {
          detailsEl.setAttribute('open', 'true');
          const newHeight = this.COLLAPSED_HEIGHT + this.MARGIN_HEIGHT + contentEl.offsetHeight;
          detailsEl.style.height = `${newHeight}px`;
        });
      } else {
        requestAnimationFrame(() => {
          detailsEl.style.height = `${this.COLLAPSED_HEIGHT}px`;
          detailsEl.removeAttribute('open');
        });
      }
    }
  }

  toggle(event: MouseEvent) {
    event.preventDefault();
    this.onToggle(this.index);
  }
}
