import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

export interface BreadcrumbItem {
  label?: string;
  url?: string;
  params?: any;
}

@Component({
  selector: 'oes-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  breadcrumbItems: BreadcrumbItem[];

  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute) {
    this.breadcrumbItems = this.generateCrumbs(this._activatedRoute.root);
    _router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((next) => {
      this.breadcrumbItems = this.generateCrumbs(this._activatedRoute.root);
    });
  }

  private generateCrumbs(route: ActivatedRoute, url = '', breadcrumbs: BreadcrumbItem[] = []): BreadcrumbItem[] {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      const customBreadcrumb = route?.snapshot?.queryParams?.bc;
      if (customBreadcrumb?.length) {
        breadcrumbs[breadcrumbs.length - 1].label = customBreadcrumb;
      }
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child?.snapshot?.url?.map(segment => segment.path).join('/');
      if (routeURL && routeURL !== '') {
        url += `/${routeURL}`;
      }

      if (child?.snapshot?.data) {
        const label = child.snapshot.data['breadcrumb'];
        if (label && label !== '') {
          const params = child.snapshot.data['params'];
          if (params) {
            breadcrumbs.push({label, url, params});
          } else {
            breadcrumbs.push({label, url});
          }
        }
      }

      return this.generateCrumbs(child, url, breadcrumbs);
    }
  }

}
