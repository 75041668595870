import { Injectable } from '@angular/core';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';

import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { DateFilterComponent } from '@shared/ag-grid/component/date-filter/date-filter.component';
import { GridState } from '@shared/ag-grid/gird-state';


@Injectable({
  providedIn: 'root'
})
export class ConnectionsPayoutGridSettingService extends BaseAgGridSettingsService {
  currentGridState: GridState = new GridState();
  defaultGridState: GridState = new GridState();
  initialGridState: GridState = new GridState();

  _columnApi: ColumnApi;
  _gridApi: GridApi;
  _gridStateStorageKey: string;

  private column = {};
  private origin = {};
  private type = {};

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService) {
    super(_agGridServicesWrapperService);
    this.column = _agGridServicesWrapperService._translateService.instant('connections-payout.columns');
    this.origin = _agGridServicesWrapperService._translateService.instant('connections-payout.origin');
    this.type = _agGridServicesWrapperService._translateService.instant('connections-payout.type');
  }

  public getGridOptions(): GridOptions {
    return <GridOptions>{
      autoGroupColumnDef: {
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          checkbox: true
        }
      },
      columnDefs: this.getColumnSetting(),
      components: {
        agDateInput: DateFilterComponent // overwrite ag-grid agDateInput with our DateFilterComponent
      },
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true
      },
      enableRangeSelection: true,
      groupHideOpenParents: true,
      localeText: this.globalTranslations,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading Payouts...</span>',
      rowGroupPanelShow: 'always',
      rowHeight: 80,
      sideBar: {
        toolPanels: ['columns']
      },
      suppressDragLeaveHidesColumns: true,
      suppressRowClickSelection: false,
    };
  }

  // Column Properties
  // https://www.ag-grid.com/javascript-grid-column-properties/
  public getColumnSetting(): any {
    const colDef = [
      // Name
      {
        headerName: this.column['name'],
        headerTooltip: this.column['name'],
        field: 'name',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: true,
        width: 140,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        enableRowGroup: true,
      },
      // Connections Approved
      {
        headerName: this.column['connections-approved'],
        headerTooltip: this.column['connections-approved'],
        field: 'connectionsApproved',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: true,
        width: 180,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        enableRowGroup: true,
        cellStyle: (params) => {
          return this.cellStyleRight(params.value);
        },
        cellRenderer: (params) => {
          return params.value === 0 || params.value ? params.value : '-';
        }
      },
      // Connections Paid
      {
        headerName: this.column['connections-paid'],
        headerTooltip: this.column['connections-paid'],
        field: 'connectionsPaid',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: true,
        width: 180,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        enableRowGroup: true,
        cellStyle: (params) => {
          return this.cellStyleRight(params.value);
        },
        cellRenderer: (params) => {
          return params.value === 0 || params.value ? params.value : '-';
        }
      },
      // Payout Amount
      {
        headerName: this.column['payout-amount'],
        headerTooltip: this.column['payout-amount'],
        field: 'payoutAmount',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        enableRowGroup: true,
        cellStyle: (params) => {
          return this.cellStyleRight(params.value);
        },
        cellRenderer: (params) => {
          return params.value === 0 || params.value ? this.displayAsCurrency(params.value) : '-';
        }
      },
      // Payout Amount in Currency
      {
        headerName: this.column['currency'],
        headerTooltip: this.column['currency'],
        field: 'payoutCurrency',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: true,
        width: 220,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        enableRowGroup: true,
        cellStyle: (params) => {
          return this.cellStyleRight(params.data?.payoutAmount);
        },
        cellRenderer: (params) => {
          if (params?.value && params?.data?.payoutCurrency !== 'USD') {
            return this.displayAsAltCurrency(params.value, params.data?.payoutAmount * params.data?.payoutExchangeRate);
          }
          return '-';
        }
      },
      // Exchange Rate
      {
        headerName: this.column['exchange-rate'],
        headerTooltip: this.column['exchange-rate-tt'],
        field: 'payoutExchangeRate',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        enableRowGroup: true,
        cellStyle: (params) => {
          return this.cellStyleRight(params.value);
        },
        cellRenderer: (params) => {
          if (params?.data?.payoutCurrency !== 'USD') {
            return params.value;
          }
          return '-';
        }
      },
      // Payout Date
      {
        headerName: this.column['payout-date'],
        headerTooltip: this.column['payout-date'],
        field: 'payoutDate',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: true,
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          if (params?.value) {
            return this.getLocalDate(params, 'dd MMMM yyyy HH:mm');
          }
        },
        filterParams: {
          comparator: this.dateComparator,
          clearButton: true
        },
        enableRowGroup: true
      },
      // Payout Type
      {
        headerName: this.column['payout-type'],
        headerTooltip: this.column['payout-type'],
        field: 'payoutType',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        enableRowGroup: true,
        cellRenderer: (params) => {
          if (params.value) {
            return this.type[params.value];
          }
          return '-';
        }
      },
      // Payout Origin
      {
        headerName: this.column['payout-origin'],
        headerTooltip: this.column['payout-origin'],
        field: 'payoutOrigin',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        enableRowGroup: true,
        cellRenderer: (params) => {
          if (params.value) {
            return this.origin[params.value];
          }
          return '-';
        }
      },
      // Total Claim Amount
      {
        headerName: this.column['total-claim-amount'],
        headerTooltip: this.column['total-claim-amount-tt'],
        field: 'totalClaimAmount',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        enableRowGroup: true,
        cellStyle: (params) => {
          return this.cellStyleRight(params.value);
        },
        cellRenderer: (params) => {
          return params.value ? this.displayAsCurrency(params.value) : '-';
        }
      },
      // Total Claim Amount in Currency
      {
        headerName: this.column['total-claim-amount-in-currency'],
        headerTooltip: this.column['total-claim-amount-in-currency-tt'],
        field: 'totalClaimAmount',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: true,
        width: 230,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        enableRowGroup: true,
        cellStyle: (params) => {
          return this.cellStyleRight(params.value);
        },
        cellRenderer: (params) => {
          if (params?.value && params?.data?.payoutCurrency !== 'USD') {
            return this.displayAsAltCurrency(params.data.payoutCurrency, params.value * params.data.payoutExchangeRate);
          }
          return '-';
        }
      },
      // % of Total Claim Amount
      {
        headerName: this.column['total-claim-percent'],
        headerTooltip: this.column['total-claim-percent-tt'],
        field: 'totalClaimAmount',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        enableRowGroup: true,
        cellStyle: (params) => {
          if (params.value === 0) {
            return this.cellStyleRight(params.value);
          }
          if (params?.value) {
            return this.cellStyleRight(params.data.payoutAmount / params.data.totalClaimAmount);
          }
          return null;
        },
        cellRenderer: (params) => {
          if (params?.data?.payoutAmount && params!.data?.totalClaimAmount) {
            return this.displayAsPercentage(params.data.payoutAmount / params.data.totalClaimAmount);
          }
          return '-';
        }
      },
      // Notes
      {
        headerName: this.column['notes'],
        headerTooltip: this.column['notes'],
        field: 'notes',
        unSortIcon: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        suppressSizeToFit: true,
        resizable: false,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        enableRowGroup: true,
        cellRenderer: (params) => {
          if (!params || !params.value) {
            return '-';
          }
          return params.value;
        }
      }
    ];
    return colDef;
  }

  private cellStyleRight(value) {
    const style = { 'display': 'flex', 'justify-content': 'flex-end', 'color': 'black'};
    if (value && value < 0) {
      style.color = 'red';
    }
    return style;
  }

  private displayAsAltCurrency(currency: string, decimal: number) {
    if (decimal < 0) {
      return currency + ' ' + '(' + Math.abs(decimal).toFixed(2) + ')';
    }
    return currency + ' ' + decimal.toFixed(2);
  }

  private displayAsCurrency(decimal: number) {
    if (decimal < 0) {
      return '($' + Math.abs(decimal).toFixed(2) + ')';
    }
    return '$' + decimal.toFixed(2);
  }

  private displayAsPercentage(decimal: number) {
    return (decimal * 100).toFixed(1) + '%';
  }
}
