import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { FinanceBusinessPlanService } from './project-set-portfolio-business-plan.service';
import { FinanceBusinessPlan } from './project-set-portfolio-business-plan.model';
import { EventService } from '@shared/event.service';
import { UnsavedChangesDialogService } from '@global/unsaved-changes-dialog/unsaved-changes-dialog.service';
import { UnsavedChangesDialogComponent } from '@global/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { DIALOG_BUTTON_STATUS, DialogButtonAction, DialogInitialState } from '@global/unsaved-changes-dialog/dialog-button-action.model';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { DynamicFormService } from '@shared/components/dynamic-form/dynamic-form.service';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import { FORM_TYPE } from '@program/shared/formio-custom-components/form-type.enum';
import { FORM_ID, ProjectGroupFormService } from '@finance/developer-portfolio/shared/project-group-form.service';
import { FormDefaultService } from '@finance/developer-portfolio/business-plan/form-default/form-default.service';
import { ComponentCanDeactivate } from '@shared/guard/project/component-can-deactivate.interface';
import { ProgramStatus } from '@finance/developer-portfolio/shared/submission/program-status.model';
import { FinanceService, PORTFOLIO_PAGE } from '@finance/developer-portfolio/shared/finance.service';
import { SubmitValidationService } from '@finance/developer-portfolio/shared/submission/submit-validation.service';
import { ProjectSetPortfolioNavigationService } from '../project-set-portfolio-navigation/project-set-portfolio-navigation.service';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { ProjectSetPortfolioConnection } from '../developer-project-set-portfolio-connection.model';
import { Program } from '@program/shared/program.model';
import { ProjectSetPortfolioService } from '@project/offtakers/project-set-portfolio/project-set-portfolio.service';

@Component({
  selector: 'oes-project-set-portfolio-business-plan',
  templateUrl: 'project-set-portfolio-business-plan.component.html',
  styleUrls: ['./project-set-portfolio-business-plan.component.scss'],
  providers: [
    ProjectGroupFormService,
    FormDefaultService
  ]
})
export class ProjectSetPortfolioBusinessPlanComponent implements  OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild(ModalDialogComponent, { static: false }) modalDialogComponent: ModalDialogComponent;

  dataLoaded = false;
  defaultFormGroup: UntypedFormGroup;
  financeBusinessPlan: FinanceBusinessPlan;
  formLoaded = false;
  isPastSubmissionDeadline = true;
  json: any = undefined;
  noBusinessPlan: boolean = false;
  programStatus: ProgramStatus;
  projectSetPortfolio: ProjectSetPortfolio;
  projectSetPortfolioProgramConnection: ProjectSetPortfolioConnection;
  readOnly: boolean;
  warningResponse = '';

  private correctWarningResponse: string;
  private customFormSaved = false;
  private ngUnsubscribe: Subject<any> = new Subject();
  private text = {};

  constructor(private _router: Router,
              private _projectSetPortfolioNavigationService: ProjectSetPortfolioNavigationService,
              private _eventService: EventService,
              private _financeBusinessPlanService: FinanceBusinessPlanService,
              private _financeService: FinanceService,
              private _dynamicFormService: DynamicFormService,
              private _documentDataService: DocumentDataService,
              private _translateService: TranslateService,
              private _projectSetPortfolioService: ProjectSetPortfolioService,
              private _unsavedChangesDialogService: UnsavedChangesDialogService,
              private _formDefaultService: FormDefaultService,
              private _submitValidationService: SubmitValidationService) {

    this.subscribeProjectSetPortfolio();

    this._translateService.get('project-group.business-plan')
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(message => {
      this.text = message;
    });

    this._translateService.get('buttons.i-understand')
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(response => {
      this.correctWarningResponse = response;
    });
  }

  ngOnInit() {
    // set this page as dirty
    this._financeService.setPageStatus(PORTFOLIO_PAGE.PORTFOLIO_DESCRIPTION, false);
    this.subscribeDialogButtonsStatus();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  // old form
  save(nextUrl?: string) {
    this.financeBusinessPlan = this._formDefaultService.getFormValues(this.financeBusinessPlan, this.defaultFormGroup.controls.defaultFormControl);
    if (this.determinePastSubmissionDeadline(this.projectSetPortfolio?.program)) {
      return this._submitValidationService.sendErrorMessage('too-late');
    }
    this._financeBusinessPlanService
    .save(this.projectSetPortfolio.id, this.financeBusinessPlan)
    .pipe(take(1))
    .subscribe((res) => {
      this.defaultFormGroup.markAsPristine();
      this._financeService.setPageStatus(PORTFOLIO_PAGE.PORTFOLIO_DESCRIPTION, true);
      this._eventService.success(this.text['saved']);
      if (nextUrl && nextUrl !== '') {
        this.goNextPage(nextUrl);
      }

    });
  }

  markAsDirty() {
    if (this.defaultFormGroup && this.defaultFormGroup.pristine) {
      this.defaultFormGroup.markAsDirty();
    }
  }

  // ComponentCanDeactivate requires
  subscribeDialogButtonsStatus() {
    this._unsavedChangesDialogService.buttonActionStatus$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((result: DialogButtonAction) => {
      // initial value is undefined
      if (result?.pageName === this.text['title']) {
        if (result?.action === DIALOG_BUTTON_STATUS.SAVE) {
          this._unsavedChangesDialogService.hide();
          if (this.defaultFormGroup) {
            // we can't save a custom form.
            this.save(result.nextUrl);
          }
        } else if (result?.action === DIALOG_BUTTON_STATUS.DO_NOT_SAVE) {
          // don't forget to clean a form status
          this.customFormSaved = true;
          if (this.defaultFormGroup) {
            this.defaultFormGroup.markAsPristine();
          }
          this.goNextPage(result.nextUrl);
        }
      }
    });
  }

  // ComponentCanDeactivate requires
  goNextPage(nextUrl: string) {
    this._unsavedChangesDialogService.hide();
    this._router.navigateByUrl(nextUrl);
  }

  // ComponentCanDeactivate requires
  showDialog(nextUrl: string) {
    let dialogInitialState;
    // skip the modal dialog
    if (this.projectSetPortfolioProgramConnection?.programShareDateTime) {
      this._router.navigateByUrl(nextUrl);
      return false;
    }

    // projectGroup has a custom form
    if (this.json && this.json.components) {
      dialogInitialState = <DialogInitialState>{
        pageName: this.text['title'],
        nextUrl: nextUrl,
        message: this.text['unsaved-message-business-plan'],
        buttons: [DIALOG_BUTTON_STATUS.CANCEL, DIALOG_BUTTON_STATUS.DO_NOT_SAVE] // unset to get default button set.
      };
    } else {
      // projectGroup has a default form
      dialogInitialState = <DialogInitialState>{
        pageName: this.text['title'],
        nextUrl: nextUrl,
      };
    }

    this._unsavedChangesDialogService.open(UnsavedChangesDialogComponent, dialogInitialState);
    return false;
  }

  // ComponentCanDeactivate requires
  hasUnsavedChanges() {
    // Read only, no need saving guard
    if (this.isPastSubmissionDeadline || this.projectSetPortfolioProgramConnection?.programShareDateTime) {
      return false;
    }
    // custom form
    if (this.json && this.json.components) {
      if (!this.customFormSaved) {
        // When user uses a custom form, always ask to save because we can't recognize Formio form is dirty or not.
        return true;
      }
    } else {
      // default form
      if (this.defaultFormGroup) {
        return this.defaultFormGroup.dirty;
      }
    }
  }

  // callback form-custom.component
  customFormAction(event) {
    this.customFormSaved = true;
    this._eventService.success(this.text['saved']);
  }

  checkWarningResponse() {
    return this.warningResponse?.toLowerCase() === this.correctWarningResponse.toLowerCase() ? false : true;
  }

  checkForWarningResponse() {
    return sessionStorage.getItem('business_plan_warning_seen') === 'true' ? true : false;
  }

  saveWarningResponse() {
    sessionStorage.setItem('business_plan_warning_seen', 'true');
    this.modalDialogComponent.hide();
  }

  private subscribeProjectSetPortfolio() {
    this._projectSetPortfolioNavigationService.portfolioObservable
      .pipe(take(1))
      .subscribe((projectSetPortfolio: ProjectSetPortfolio) => {
        this.projectSetPortfolio = projectSetPortfolio;
        this.loadCustomForm();
        this.createForm();
        this.isPastSubmissionDeadline = this.determinePastSubmissionDeadline(projectSetPortfolio?.program);
      });
    this._projectSetPortfolioNavigationService.portfolioProgramConnectionObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projectSetPortfolioConnection: ProjectSetPortfolioConnection) => {
        this.projectSetPortfolioProgramConnection = projectSetPortfolioConnection;
      });
    this._projectSetPortfolioNavigationService.portfolioReadOnlyObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((readOnly: boolean) => {
        this.readOnly = readOnly;
      });
  }

  private multipleUsersWarning() {
    if (!this.checkForWarningResponse()) {
      this.modalDialogComponent.show();
    }
  }

  private createForm() {
    this.defaultFormGroup = new UntypedFormGroup({
      defaultFormControl: new UntypedFormControl('') // form-default.component uses
    });
  }

  private loadCustomForm() {
    this._projectSetPortfolioService.getForm(this.projectSetPortfolio.id, FORM_ID.BUSINESS_PLAN)
    .pipe(take(1))
    .subscribe(prequalFormJson => {
      this.json = this._dynamicFormService.cleaningSaveButton(prequalFormJson);
      this.dataLoaded = true;
      this._documentDataService.setFileUploadStorageModel(this.projectSetPortfolio?.ownerOrganization?.id, FORM_TYPE.PROJECT_SET_PORTFOLIO_BUSINESS_PLAN, this.projectSetPortfolio.id);
      if (!this.readOnly) {
        this.multipleUsersWarning();
      }
    }, (error: any) => {
      this.dataLoaded = true;
      if (error?.error?.message === 'No value present') {
        this.noBusinessPlan = true;
      }
    });
  }

  private determinePastSubmissionDeadline(program: Program)  {
    if (program?.endDateTime) {
      return new Date(program.endDateTime) < new Date();
    }
    return false;
  }
}
