import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy, OnDestroy, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { IMultiSelectSettings, IMultiSelectOption, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { debounce, isArray } from 'lodash-es';

export interface SelectOption {
  id: string;
  name: string;
}

// Note: parent can disable this form
// this.formGroup.controls['selectOption'].disable();
@Component({
  selector: 'oes-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit, OnChanges, OnDestroy {
  @Input() customText: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Search',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Select',
    allSelected: 'All selected',
  };
  @Input() class: string = '';
  @Input() controlName = 'selectOption';
  @Input() dataTestClass: string;
  @Input() enableCheckAll = true;
  @Input() enableSearch = true;
  @Input() enableUncheckAll = true;
  @Input() hint: string;
  @Input() initialSelections: any[];
  @Input() items: IMultiSelectOption[];
  @Input() lazySearch: boolean = false;
  @Input() multiSelectSettings: IMultiSelectSettings;
  @Input() parentFormGroup: UntypedFormGroup; // need to contain 'selectOption' FormControl. Read the value like this parentFormGroup.get('selectOption').value in parent component
  @Input() required: boolean = false;
  @Input() selection = 'single';
  @Input() selectionNumber: number | string; // number or 'all': Maximum number of items that may be selected (0 = no limit)

  @Output() searchChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  private ngUnsubscribe: Subject<any> = new Subject();
  private searchText: string = '';

  public dropdownForm: UntypedFormGroup;
  public dropdownSettings: IMultiSelectSettings;

  constructor(private _el: ElementRef, private _formBuilder: UntypedFormBuilder) {
    this.emitSearch = debounce(this.emitSearch, 500);
  }

  emitSearch(event) {
    if (event.filter !== this.searchText) {
      this.searchText = event.filter;
      this.searchChanged.emit(event.filter);
    }
  }

  ngOnInit() {
    this.class = this.class + ' ' + this._el.nativeElement.classList;
    this._el.nativeElement.classList = '';
    // Apply this.lazySearch to dropdownSettings
    this.dropdownSettings = {
      enableSearch: true,
      selectionLimit: 1,
      minSelectionLimit: 1,
      checkedStyle: 'fontawesome',
      containerClasses: 'oes-dropdown-container ', // keep space
      buttonClasses: 'oes-dropdown-button',
      itemClasses: 'oes-dropdown-item',
      dynamicTitleMaxItems: 3,
      displayAllSelectedText: false,
      showCheckAll: false,
      showUncheckAll: false,
      autoUnselect: true,
      closeOnSelect: true,
      isLazyLoad: this.lazySearch,
    };
    // change dropdown settings for multi select
    if (this.selection === 'multiple') {
      this.dropdownSettings.selectionLimit = 0;
      this.dropdownSettings.minSelectionLimit = 0;
      this.dropdownSettings.showCheckAll = this.enableCheckAll;
      this.dropdownSettings.showUncheckAll = this.enableUncheckAll;
      this.dropdownSettings.autoUnselect = false;
      this.dropdownSettings.closeOnSelect = false;
      this.dropdownSettings.displayAllSelectedText = true;
      // override with incoming settings
      this.dropdownSettings = Object.assign(
        this.dropdownSettings,
        this.multiSelectSettings
      );
    }

    if (typeof this.selectionNumber === 'number') {
      this.dropdownSettings.minSelectionLimit = this.selectionNumber;
    }

    if (!this.enableSearch) {
      this.dropdownSettings.enableSearch = this.enableSearch;
    }

    if (this.parentFormGroup) {
      this.dropdownForm = this.parentFormGroup;
    } else {
      this.dropdownForm = this._formBuilder.group({});
      this.dropdownForm.addControl(this.controlName, new UntypedFormControl());
    }
    if (this.required) {
      this.dropdownForm?.controls[this.controlName].setValidators(
        Validators.required
      );
    }

    // subscribe dropdownForm
    this.dropdownForm.controls[this.controlName].valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectionIds: any[]) => {
        if (isArray(selectionIds)) {
          this.emitSelectionUpdate(selectionIds);
        }
      });

    if (this.dataTestClass) {
      this.dropdownSettings.containerClasses += this.dataTestClass;
    }

    this.setDefaultSelection();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dropdownForm) {
      if (changes['initialSelections'] || changes['items']?.currentValue) {
        this.setDefaultSelection();
      }
    }
  }

  private setDefaultSelection() {
    // set default selection, it will emit the dropdownForm subscription
    if (this.items && this.items.length > 0 && this.dropdownForm) {
      if (
        this.initialSelections &&
        this.initialSelections.length > 0 &&
        this.initialSelections[0]
      ) {
        if (this.enableUncheckAll) {
          this.dropdownForm.controls[this.controlName].setValue(
            this.initialSelections.map((item) => item.id)
          );
        } else {
          const initialIds = this.initialSelections.map((item) => item.id);
          if (initialIds?.length > 0) {
            this.disableSelections(initialIds);
          }
        }
      } else if (
        this.selection === 'multiple' &&
        this.selectionNumber === 'all'
      ) {
        // set all items
        this.dropdownForm.controls[this.controlName].setValue(
          this.items.map((item) => item.id)
        );
      } else if (typeof this.selectionNumber === 'number' && this.selectionNumber > 0) {
        // set the first item
        this.dropdownForm.controls[this.controlName].setValue([this.items[0].id]);
      }
    }
  }

  private disableSelections(selectionIds: any[]) {
    this.items = this.items.map((item) => {
      if (selectionIds.indexOf(item.id) > -1) {
        item.disabled = true;
      }
      return item;
    });
  }

  // call from dropdownForm subscribe
  public emitSelectionUpdate(selectionIds: any[]) {
    if (selectionIds && selectionIds.length > 0) {
      if (selectionIds.length === this.items.length) {
        this.selectionChanged.emit(this.items);
      } else {
        const selectedItems = selectionIds?.map((id) => {
          const index = this.items.findIndex((item) => item.id === id);
          if (index > -1) {
            return this.items[index];
          }
        });
        this.selectionChanged.emit(selectedItems);
      }
    } else {
      this.selectionChanged.emit([]);
    }
  }
}
