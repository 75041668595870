import { BaseModel } from '@shared/base.model';
import { Organization } from '@shared/models/organization/organization.model';
import { FinancialModelType } from './financial-model-type.enum';

export class OrganizationFinancialModel extends BaseModel<OrganizationFinancialModel> {
  description: string;
  modelVersion: string;
  name: string;
  organization: Organization;
  type: FinancialModelType;

  constructor(projectFinancialModelTemplate: any) {
    super();
    this.id = projectFinancialModelTemplate.id;

    this.description = projectFinancialModelTemplate.description;
    this.modelVersion = projectFinancialModelTemplate.modelVersion;
    this.name = projectFinancialModelTemplate.name;
    this.organization = projectFinancialModelTemplate.organization;
    this.type = projectFinancialModelTemplate.type;
  }
}
