import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ROLE_TYPE } from '@user/role-type';
import { View } from '@shared/components/view-toggle-button/view.enum';
import { ProjectTariffDialogComponent } from '@shared/components/project-tariff-dialog/tariff.component';
import { SelectedProjectsService } from '@project/shared/selected-projects.service';
import { Project } from '@project/shared/project.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '@user/user.service';
import { ProjectPortfolioDialogComponent } from '@project/dialog/portfolio/portfolio.component';
import { ProjectBulkUploadDialogComponent } from '@shared/components/project-bulk-upload/bulk-upload.component';
import { ProjectType } from '@project/shared/project-type.enum';
import { ProjectService } from '@project/shared/project.service';

@Component({
  selector: 'oes-project-action',
  templateUrl: './project-action.component.html',
  styleUrls: ['./project-action.component.scss']
})
export class ProjectActionComponent implements OnInit, OnDestroy {
  @Input() projectListItems = [];
  @Input() displayMax = 20;
  @Output() installSampleProject: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() copyProjects: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() exportProjectsFinancials: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteProjects: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() exportCsv: EventEmitter<boolean> = new EventEmitter<boolean>();
  public view = View;
  private ngUnsubscribe: Subject<any> = new Subject();
  public selectedProjectIds = [];
  public selectedProjectNames = [];
  public projectType = ProjectType;

  constructor(private _router: Router,
              private _userService: UserService,
              private _bsModalService: BsModalService,
              private _projectService: ProjectService,
              private _selectedProjectsService: SelectedProjectsService) {
  }

  ngOnInit() {
    this._selectedProjectsService.projects$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((projects: Project[]) => {
      this.selectedProjectNames = projects?.map(item => item.name);
      this.selectedProjectIds = projects?.map(item => item.id);
    });
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  isDeveloper() {
    return this._userService.hasRole(ROLE_TYPE.DEVELOPER_USER);
  }

  isAdminUser() {
    return this._userService.hasRole(ROLE_TYPE.ORGANIZATION_ADMIN);
  }

  isSysAdmin() {
    return this._userService.hasRole(ROLE_TYPE.SYSTEM_ADMIN);
  }

  public newProject(projectType: ProjectType) {
    this._router.navigate(['/oes/projects/create'], {queryParams: {projectType: projectType}});
  }

  public downloadFinancialOverviewReport() {
    this._projectService.downloadFinancialOverviewReport(this.selectedProjectIds);
  }

  public downloadCustomerSummaryReport() {
    this._projectService.downloadCustomerSummaryReport(this.selectedProjectIds);
  }

  emitInstallSampleProject() {
    this.installSampleProject.emit(true);
  }

  emitCopyProjects() {
    this.copyProjects.emit(true);
  }

  emitExportProjectFinancials() {
    this.exportProjectsFinancials.emit(true);
  }

  emitDeleteProjects() {
    this.deleteProjects.emit(true);
  }

  public emitSwitchView(name: View) {
    switch (name) {
      case View.TARIFF: {
        const dialogInitialState = {
          selectedProjectNames: this.selectedProjectNames,
          selectedProjectIds: this.selectedProjectIds,
          displayMax: 20
        };
        this.openDialog(ProjectTariffDialogComponent, dialogInitialState);
        break;
      }
      case View.PORTFOLIO: {
        const dialogInitialState = {
          selectedProjectNames: this.selectedProjectNames,
          selectedProjectIds: this.selectedProjectIds,
          projectListItems: this.projectListItems,
          displayMax: 20
        };
        this.openDialog(ProjectPortfolioDialogComponent, dialogInitialState, 'modal-md');
        break;
      }
      case View.BULK_UPLOAD: {
        this.openDialog(ProjectBulkUploadDialogComponent);
        break;
      }
    }
  }

  private openDialog(component: any, initialState?: any, size?: string) {
    const config = {
      initialState: initialState ? initialState : {},
      class: size ? size : 'modal-xl',
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true
    };
    this._bsModalService.show(component, config);
  }
}
