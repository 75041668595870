import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class RestToolsService {

  constructor(private _oauthService: OAuthService) {}

  getAsyncOptions(): object {
    const headers = new HttpHeaders()
        .set('Authorization', 'Bearer ' + this._oauthService.getAccessToken())
        .set('ignoreProgressBar', '');
    const options = {
      headers: headers
    };
    return options;
  }

}
