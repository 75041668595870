import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FinancialModelType } from '@project/detail/financials/organization-financial-model/financial-model-type.enum';
import { OrganizationFinancialModel } from '@project/detail/financials/organization-financial-model/organization-financial-model.model';
import { EventService } from '@shared/event.service';
import { FileNameCheckerService } from '@shared/services/file-name-checker.service';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { GridApi, GridOptions } from 'ag-grid-community';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ManageOrganizationFinancialmodelsGridSettingService } from './manage-organization-financial-models-grid-setting.service';
import { ManageOrganizationFinancialModelsService } from './manage-organization-financial-models.service';

@Component({
  selector: 'oes-manage-organization-financial-models',
  templateUrl: 'manage-organization-financial-models.component.html',
  styleUrls: ['manage-organization-financial-models.component.scss']
})

export class ManageOrganizationFinancialModelsComponent implements OnInit, OnDestroy {
  @ViewChild('createEditModal', { static: false }) createEditModal: ModalDirective;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  editMode: boolean = false;
  file: File;
  modelFormGroup = new UntypedFormGroup({});
  gridApi: GridApi;
  gridOptions: GridOptions = {};
  selectedOrganizationFinancialModel: OrganizationFinancialModel;

  private ngUnsubscribe: Subject<any> = new Subject();
  private user: User;

  constructor(private _manageOrganizationFinancialModelsService: ManageOrganizationFinancialModelsService,
              private _eventService: EventService,
              private _manageOrganizationFinancialModelsGridSettingService: ManageOrganizationFinancialmodelsGridSettingService,
              private _userService: UserService,
              private _translateService: TranslateService,
              private _fileNameCheckerService: FileNameCheckerService) {
    const orgId = this._userService.organizationId;
    this.gridOptions = this._manageOrganizationFinancialModelsGridSettingService.getGridOptions(orgId);
    this.gridOptions['context'] = {
      gridService: this
    };
  }

  ngOnInit() {
    this._userService.getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
    this.createForm();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.getTemplates();
  }

  closeModal() {
    this.createEditModal.hide();
    this.editMode = false;
    this.createForm();
  }

  validForm() {
    return this.modelFormGroup.valid;
  }

  createForm() {
    this.modelFormGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl(''),
      id: new UntypedFormControl('')
    });
    this.modelFormGroup.controls.id.disable();
  }

  changeFileInput(event) {
    if (event?.target?.files) {
      this.file = event.target.files[0];
    }
  }

  createEditModel() {
    const name = this.modelFormGroup.controls.name.value;
    const description = this.modelFormGroup.controls.description.value;
    const ofm = new OrganizationFinancialModel({
      id: this.modelFormGroup.controls.id?.value,
      name: name,
      description: description,
      modelUploaded: false,
      type: FinancialModelType.PRIVATE,
      organization: this.user.organization
    });

    if (!ofm.id) {
      this._manageOrganizationFinancialModelsService.createNewModel(this.user.organization.id, ofm)
        .pipe(take(1))
        .subscribe((savedModel: OrganizationFinancialModel) => {
          this.createEditModal.hide();
          this.selectedOrganizationFinancialModel = savedModel;
          this.uploadModel();
        });
    } else {
      this._manageOrganizationFinancialModelsService.editModel(this.user.organization.id, ofm)
        .pipe(take(1))
        .subscribe((savedModel: OrganizationFinancialModel) => {
          this.selectedOrganizationFinancialModel = savedModel;
          this.uploadModel();
        });
    }
  }

  deleteModel(event) {
    this._manageOrganizationFinancialModelsService.deleteModel(this.user.organization.id, this.selectedOrganizationFinancialModel.id)
      .pipe(take(1))
      .subscribe((response: string) => {
        this._eventService.success(this._translateService.instant('general-message.success.deleted'));
        this.createEditModal.hide();
        this.getTemplates();
      }, error => {
        if (error?.status === 400) {
          this._eventService.error('This model cannot be deleted because it is in use.');
        }
      });
  }

  getTemplates() {
    this._manageOrganizationFinancialModelsService.findAllOrgModels(this.user?.organization?.id)
      .pipe(take(1))
      .subscribe((models: OrganizationFinancialModel[]) => {
        if (models) {
          this.gridApi.showLoadingOverlay();
          this.gridApi.setRowData(models);
          this.gridApi.hideOverlay();
        } else {
          this.gridApi.setRowData([]);
          this.gridApi.showNoRowsOverlay();
        }
        this.createEditModal.hide();
        this.editMode = false;
        this.createForm();
      });
  }

  uploadModel() {
    if (this.file) {
      const s3SafeName = this._fileNameCheckerService.s3Safe(this.file.name);
      const formData = new FormData();

      formData.append('file', this.file, s3SafeName);

      this._manageOrganizationFinancialModelsService.uploadOrgModel(this.user.organization.id, this.selectedOrganizationFinancialModel.id, formData)
        .pipe(take(1))
        .subscribe(res => {
          this._eventService.success('Upload successful');
          this.reset();
        });
    } else {
      this.reset();
    }
  }

  private reset() {
    this.selectedOrganizationFinancialModel = null;
    this.file = null;
    this.fileInput.nativeElement.value = '';
    this.createEditModal.hide();
    this.getTemplates();
  }

  // call from cell-button.component
  cellButtonClicked(event: any) {
    switch (event?.key) {
      case 'edit':
        this.selectedOrganizationFinancialModel = event?.data;
        this.modelFormGroup.controls.name.setValue(this.selectedOrganizationFinancialModel.name);
        this.modelFormGroup.controls.description.setValue(this.selectedOrganizationFinancialModel.description);
        this.modelFormGroup.controls.id.setValue(this.selectedOrganizationFinancialModel.id);
        if (this.selectedOrganizationFinancialModel.type === FinancialModelType.PUBLIC) {
          this.modelFormGroup.controls.name.disable();
        }
        this.editMode = true;
        this.createEditModal.show();
        break;
      default:
        break;
    }
  }

}
