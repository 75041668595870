<ng-container *ngIf="simple; else showFormio">
  <oes-formio-simple-form
    [json]="updatedForm">
  </oes-formio-simple-form>
</ng-container>
<ng-template #showFormio>
  <formio
    [form]="updatedForm"
    [readOnly]="readOnly"
    [submission]="submissionData"
    (customEvent)="emitEvent($event)"
    (invalid)="emitInvalid($event)"
    (change)="onChange($event)">
  </formio>
  <ng-container *ngIf="!readOnly">
    <ng-container *ngIf="invalidItems?.length > 0; else ValidForm">
      <oes-notify
        [iconName]="'fa-exclamation'"
        [type]="'error'">
        <div class="error-list">
          <accordion>
            <accordion-group #GroupHeader>
              <div accordion-heading>
                <div class="error-title">
                  <i class="fa" [ngClass]="{'fa-chevron-down': GroupHeader?.isOpen ,'fa-chevron-right':!GroupHeader?.isOpen}"></i>
                  {{'general-message.error.form-invalid' | translate}}
                </div>
              </div>
              <ul>
                <ng-container *ngFor="let item of invalidItems">
                  <ng-container [ngSwitch]="item.type")>
                    <ng-container *ngSwitchCase="type.maxLength">
                      <li class="error">
                        <div class="title">{{item.label}}:</div>
                        {{'form.error.max-length' | translate : {limit: item.value} }}
                      </li>
                    </ng-container>
                    <ng-container *ngSwitchCase="type.maxWordLength">
                      <li class="error">
                        <div class="title">{{item.label}}:</div>
                        {{'form.error.max-word-length' | translate : {limit: item.value} }}
                      </li>
                    </ng-container>
                    <ng-container *ngSwitchCase="type.required">
                      <li class="error">
                        <div class="title">{{item.label}}:</div>
                        {{'form.error.required' | translate}}
                      </li>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ul>
            </accordion-group>
          </accordion>
        </div>
      </oes-notify>
    </ng-container>
    <ng-template #ValidForm>
      <oes-notify
        [iconName]="'fa-exclamation'"
        [message]="'general-message.success.form-valid' | translate"
        [type]="'success'">
      </oes-notify>
    </ng-template>
  </ng-container>
  <!-- new submit button -->
  <ng-container *ngIf="enableSubmit">
    <button
      class="btn btn-primary"
      (click)="beforeSubmit()"
      [disabled]="invalidItems?.length > 0 || readOnly">
      {{'buttons.submit' | translate}}
    </button>
  </ng-container>
</ng-template>
