<div class="list-header">
  <ng-container *ngIf="listType === 'ASSETS'">
    <div class="bulk-stage-button-wrapper">
      <button *ngIf="!selectedProjects.length"
              [tooltip]="'project-sharing.bulk-button-tooltip' | translate"
              class="btn btn-primary btn-hover-tooltip"
              placement="bottom"
              container="body">
      </button>
      <button [disabled]="!selectedProjects.length"
              class="btn btn-primary"
              (click)="changeStageBulk.emit(true)">{{ 'project-sharing.bulk-button' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="listType === 'MAIN'">
    <div class="ml-2">
      <oes-view-toggle-button [mapView]="true">
      </oes-view-toggle-button>
    </div>
  </ng-container>

</div>
