<ng-container [formGroup]="otherActivitiesFormGroup">
  <div class="other-activities">
    <h5 class="bold mb-3">{{'deal-description.activities.header' | translate}}</h5>
    <label class="input-control input-control--checkbox mb-3">
      {{'deal-description.activities.appliance-label' | translate}}
      <input type="checkbox" formControlName="equipmentLeasing">
      <span class="input-control__indicator"></span>
    </label>
    <div class="activities-textarea-wrap mb-4">
      <oes-quill
        formControlName="equipmentLeasingDescription"
        [editorDisabled]="isPastSubmissionDeadline || !otherActivitiesFormGroup?.controls?.equipmentLeasing?.value"
        [characterLimit]="1000"
        [initialValue]="financeBusinessPlan?.equipmentLeasingDescription"
        [placeholder]="'deal-description.activities.description' | translate">
      </oes-quill>
    </div>

    <label class="input-control input-control--checkbox mb-3">
      {{'deal-description.activities.water-label' | translate}}
      <input type="checkbox" formControlName="waterSanitation">
      <span class="input-control__indicator"></span>
    </label>
    <div class="activities-textarea-wrap mb-4">
      <oes-quill
        formControlName="waterSanitationDescription"
        [editorDisabled]="isPastSubmissionDeadline || !otherActivitiesFormGroup?.controls?.waterSanitation?.value"
        [characterLimit]="1000"
        [initialValue]="financeBusinessPlan?.waterSanitationDescription"
        [placeholder]="'deal-description.activities.description' | translate">
      </oes-quill>
    </div>

    <label class="input-control input-control--checkbox mb-3">
      {{'deal-description.activities.business-label' | translate}}
      <input type="checkbox" formControlName="smallBusinessFinancing">
      <span class="input-control__indicator"></span>
    </label>
    <div class="activities-textarea-wrap mb-4">
      <oes-quill
        formControlName="smallBusinessFinancingDescription"
        [editorDisabled]="isPastSubmissionDeadline || !otherActivitiesFormGroup?.controls?.smallBusinessFinancing?.value"
        [characterLimit]="1000"
        [initialValue]="financeBusinessPlan?.smallBusinessFinancingDescription"
        [placeholder]="'deal-description.activities.description' | translate">
      </oes-quill>
    </div>

    <label class="input-control input-control--checkbox mb-3">
      {{'deal-description.activities.other-label' | translate}}
      <input type="checkbox" formControlName="otherOpportunities">
      <span class="input-control__indicator"></span>
    </label>
    <div class="activities-textarea-wrap">
      <oes-quill
        formControlName="otherOpportunitiesDescription"
        [editorDisabled]="isPastSubmissionDeadline || !otherActivitiesFormGroup?.controls?.otherOpportunities?.value"
        [characterLimit]="1000"
        [initialValue]="financeBusinessPlan?.otherOpportunitiesDescription"
        [placeholder]="'deal-description.activities.description' | translate">
      </oes-quill>
    </div>
  </div>
</ng-container>
