import { AfterViewInit, Directive, ElementRef, HostListener } from "@angular/core";
import { insideRectCheck } from "@shared/utility";

@Directive({
  selector: '[oesAgColumnsOffclick]'
})
export class AgColumnsOffclickDirective implements AfterViewInit {
  private columnsButton: HTMLElement;
  private toolPanel: HTMLElement;

  constructor(private el: ElementRef) { }

  ngAfterViewInit(): void {
    this.columnsButton = this.el.nativeElement.querySelector('.ag-side-button:first-child button');
    this.toolPanel = this.el.nativeElement.querySelector('.ag-tool-panel-wrapper');
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.columnsButton || !this.toolPanel) {
      return;
    }
    const clickedInsideToolPanel = insideRectCheck(event.clientX, event.clientY, this.toolPanel);
    const clickedInsideSidebarButtons = insideRectCheck(event.clientX, event.clientY, this.columnsButton);

    if (!clickedInsideToolPanel
        && !clickedInsideSidebarButtons
        && !this.toolPanel.classList.contains('ag-hidden')) {
      this.columnsButton.click();
    }
  }
}
