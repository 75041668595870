import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProjectListType } from '@project/shared-projects-list/project-list-type.enum';
import { Project } from '@project/shared/project.model';
import { ProjectService } from '@project/shared/project.service';
import { SelectedProjectsService } from '@project/shared/selected-projects.service';
import { SimpleProject } from '@project/shared/simple-project.model';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { GridState } from '@shared/ag-grid/gird-state';
import { View } from '@shared/components/view-toggle-button/view.enum';
import { EventService } from '@shared/event.service';
import { DynamicHomeService } from '@shared/services/dynamic-home.service';
import { ColumnApi, GridApi, GridOptions, ISimpleFilterModel } from 'ag-grid-community';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';

import { MyProjectGridSettingService } from './my-projects-grid-setting.service';
import { ProgramClaimsKPIService } from '@program/program-detail/program-claims/program-claims-kpi.service';
import { ProjectType } from '@project/shared/project-type.enum';

@Component({
  selector: 'oes-my-projects-list',
  templateUrl: 'my-projects-list.component.html',
  styleUrls: ['./my-projects-list.component.scss'],
  providers: [
  ],
})
export class MyProjectsListComponent implements OnInit, OnDestroy {
  @Input() programId: string;
  @Input() listType: ProjectListType = ProjectListType.MAIN;
  @ViewChild('deleteModal', {static: false}) deleteModal: ModalDirective;

  activeProjectTypes: string[] = [];
  columnApi: ColumnApi;
  copying: boolean = false;
  currentView = View.LIST;
  defaultGridState: GridState;
  filterModel: ISimpleFilterModel;
  gridApi: GridApi;
  gridOptions: GridOptions = {};
  gridState: GridState;
  mapProjects: any[];
  projects: any[]; // flatten Project
  projectTypes: string[];
  selectedProjectIds = [];
  selectedProjectNames = [];
  view = View;

  private gridStateStorageKey: string;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _eventService: EventService,
              private _activatedRoute: ActivatedRoute,
              private _projectService: ProjectService,
              private _programClaimsKpiService: ProgramClaimsKPIService,
              public _myProjectGridSettingService: MyProjectGridSettingService,
              private _selectedProjectsService: SelectedProjectsService,
              private _dynamicHomeService: DynamicHomeService,
              private _translateService: TranslateService) {
    const types: string[] = Object.values(ProjectType);
    const typeIndexToRemove = types.findIndex(t => t === 'MARKET');
    types.splice(typeIndexToRemove, 1);  // Not showing MARKET
    this.projectTypes = types;
  }

  // callback delete modal
  get deleteProjectList() {
    return this.selectedProjectNames.join(',') + '.';
  }

  ngOnInit() {
    this.gridOptions = this._myProjectGridSettingService.getGridOptions(this.listType);
    this.gridInit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  // ag-grid is ready
  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._myProjectGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._myProjectGridSettingService.buildDefaultGridState();
    this.getProjects();
  }

  installSampleProject() {
    this._projectService.createSampleProject()
    .pipe(take(1))
    .subscribe((project: Project) => {
      this.getProjects();
      this._eventService.success(this._translateService.instant('success-message.sample-project-created'));
    });
  }

  // callback project-action
  exportProjectFinancialModel() {
    if (this.selectedProjectIds) {
      this._projectService.exportProjectFinancialModelAsync(this.selectedProjectIds);
    }
  }

  // callback project-action
  copyProjects() {
    const observables = this.selectedProjectIds.map(id => this._projectService.copy(id));
    // clear selected projects
    this._selectedProjectsService.projects = undefined;
    this.copying = true;
    forkJoin(observables)
    .pipe(
      take(1),
      finalize(() => this.copying = false)
    )
    .subscribe((projects: Project[]) => {
      this.selectedProjectIds = [];
      this.selectedProjectNames = [];
      this.getProjects();
    });
  }

  // callback delete modal
  deleteProjects() {
    this.deleteModal.show();
    const observables = this.selectedProjectIds.map(id => this._projectService.remove(id));

    forkJoin(observables)
    .pipe(take(1))
    .subscribe((response: any[]) => {
      this._eventService.success(this._translateService.instant('general-message.success.deleted'));
      this.selectedProjectIds = [];
      this.selectedProjectNames = [];
      this.deleteModal.hide();
      this.getProjects();
    },
    error => {
      this._eventService.error(error?.error?.message);
      this.deleteModal.hide();
    });
  }

  // callback delete button on project-action
  prepareDelete() {
    this.deleteModal.show();
  }

  // callback delete modal
  cancelDelete() {
    this.selectedProjectIds = [];
    this.selectedProjectNames = [];
    this.deleteModal.hide();
  }

  // ag-grid callback: checkbox
  selectionChanged(event) {
    const selectedProjects = event.api.getSelectedNodes().map(item => item.data);
    this._selectedProjectsService.projects = selectedProjects;
    this.selectedProjectNames = selectedProjects.map(item => item.name);
    this.selectedProjectIds = selectedProjects.map(item => item.id);
  }

  // ag-grid callback: filter, sort and group
  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._myProjectGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
      this.filterModel = this.gridApi.getFilterModel();
    }
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList:
        this._myProjectGridSettingService.exportCsv(this.gridApi, 'MyProjects', true);
        break;
      case GridSubAction.reload:
        this.getProjects();
        break;
      case GridSubAction.clearFilter:
        this._myProjectGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        this.storeGridState();
        break;
    }
  }

  private gridInit() {
    this.gridStateStorageKey = this._myProjectGridSettingService.buildGridStateStorageKey('myProjects');
    this.checkQueryParams();
  }

  private getProjects() {
    if (this.listType !== ProjectListType.MAIN && this.programId) {
      this._projectService.myListByProgram(this.programId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projects: SimpleProject[]) => {
        this.currentView = View.LIST;
        this.setAgGrid(projects);
        this.mapProjects = undefined;
        if (this.listType === ProjectListType.CLAIMS) {
          this._programClaimsKpiService.buildClaimsKPIs(projects);
        }
      });
    } else {
      const requests = [this._projectService.myList(),
                        this._projectService.projectMilestoneMap()];
      forkJoin(requests).subscribe(responses => {
        const projects: SimpleProject[] = responses[0];
        const milestonesMap: any = responses[1];
        if (this.currentView === View.LIST) {
          let combinedProjects = this.addMilestoneMap(projects, milestonesMap);
          this.setAgGrid(combinedProjects);
          combinedProjects = undefined;
          this.mapProjects = undefined;
        } else if (this.currentView === View.MAP) {
          this.mapProjects = projects;
        }
        this._dynamicHomeService.saveUrl();
      });
    }
  }

  private addMilestoneMap(projects: SimpleProject[], milestonesMap: any): SimpleProject[] {
    return projects.map(project => {
      if (milestonesMap[project.id]) {
        project.milestones = milestonesMap[project.id];
      }
      return project;
    });
  }

  private setAgGrid(projects: SimpleProject[]) {
    if (projects?.length > 0) {
      this.gridApi.showLoadingOverlay();
      this.projects = projects;
      this.gridApi.setRowData(this.projects);
      this._myProjectGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
      this.gridApi.hideOverlay();
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  private checkQueryParams() {
    this._activatedRoute.queryParams
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(params => {
      if (params?.type) {
        this.currentView = params.type;
        if (this.currentView === View.MAP) {
          this.getProjects();
        }
      }
    });
  }
}
