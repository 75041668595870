import { DatePipe } from '@angular/common';
import { Injectable, SecurityContext } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Program } from '@program/shared/program.model';
import { ProjectType } from '@project/shared/project-type.enum';
import { Project } from '@project/shared/project.model';
import { CurrencyExchange } from '@shared/models/currency-exchange/currency-exchange.model';
import { ReferenceType } from '@shared/references.model';
import { Country } from '@shared/services/country/country.model';
import { Region } from '@shared/services/region/region.model';
import { GreaterThanZeroValidator } from '@shared/validators/greater-than-zero-validator';
import { SpecificationFormRule } from './project-type-rules/specification-form-rule';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SpecificationFormService {
  private _countries: Country[];
  private _regions: Region[];
  private _programs: Program[];
  private _projectStatusTypes: ReferenceType[];
  private _currencyTypes: ReferenceType[];
  private _projectTypes: ReferenceType[];
  private _exchangeRates: CurrencyExchange[];
  public _dirty = false;

  constructor(private _datePipe: DatePipe,
              private _sanitizer: DomSanitizer,
              private _translateService: TranslateService) {
  }

  set countries(countries: Country[]) {
    this._countries = countries;
  }
  set regions(regions: Region[]) {
    this._regions = regions;
  }
  set programs(programs: Program[]) {
    this._programs = programs;
  }
  set projectStatusTypes(projectStatusTypes: ReferenceType[]) {
    this._projectStatusTypes = projectStatusTypes;
  }
  set currencyTypes(currencyTypes: ReferenceType[]) {
    this._currencyTypes = currencyTypes;
  }
  set projectTypes(projectTypes: ReferenceType[]) {
    this._projectTypes = projectTypes;
  }
  set exchangeRates(exchangeRates: CurrencyExchange[]) {
    this._exchangeRates = exchangeRates;
  }
  set dirty(dirty: boolean) {
    this._dirty = dirty;
  }

  get countries() {
    return this._countries;
  }
  get regions() {
    return this._regions;
  }
  get programs() {
    return this._programs;
  }
  get projectStatusTypes() {
    return this._projectStatusTypes;
  }
  get currencyTypes(): ReferenceType[] {
    return this._currencyTypes;
  }
  get projectTypes(): ReferenceType[] {
    return this._projectTypes;
  }
  get exchangeRates() {
    return this._exchangeRates;
  }
  get dirty() {
    return this._dirty;
  }

  public createForm(isDisabled: boolean, specificationFormRule: SpecificationFormRule): UntypedFormGroup {
    const fields = specificationFormRule?.fields;
    const formGroup = new UntypedFormGroup({
      projectTypeControl: new FormControl<ProjectType>({value: null, disabled: true}, {validators: Validators.required})
    });
    if (fields?.name?.display) {
      formGroup.addControl('nameControl', new UntypedFormControl({ value: '', disabled: isDisabled }));
      if (fields.name?.required) {
        formGroup.controls.nameControl.setValidators([Validators.required]);
      }
    }
    if (fields?.description?.display) {
      formGroup.addControl('descriptionControl', new UntypedFormControl({ value: '', disabled: isDisabled }));
      if (fields.description?.required) {
        formGroup.controls.descriptionControl.setValidators([Validators.required]);
      }
    }
    if (fields?.postalCode?.display) {
      formGroup.addControl('postalCodeControl', new UntypedFormControl({ value: '', disabled: isDisabled }));
      if (fields.postalCode?.required) {
        formGroup.controls.postalCodeControl.setValidators([Validators.required]);
      }
    }
    if (fields?.address1?.display) {
      formGroup.addControl('address1Control',  new UntypedFormControl({ value: '', disabled: isDisabled }));
      if (fields.address1?.required) {
        formGroup.controls.address1Control.setValidators([Validators.required]);
      }
    }
    if (fields?.address2?.display) {
      formGroup.addControl('address2Control', new UntypedFormControl({ value: '', disabled: isDisabled }));
      if (fields.address2?.required) {
        formGroup.controls.address2Control.setValidators([Validators.required]);
      }
    }
    if (fields?.city?.display) {
      formGroup.addControl('cityControl', new UntypedFormControl({ value: '', disabled: isDisabled }));
      if (fields.city?.required) {
        formGroup.controls.cityControl.setValidators([Validators.required]);
      }
    }
    if (fields?.state?.display) {
      formGroup.addControl('regionControl', new UntypedFormControl({ value: '', disabled: isDisabled }));
      if (fields.state?.required) {
        formGroup.controls.regionControl.setValidators([Validators.required]);
      }
    }
    if (fields?.country?.display) {
      formGroup.addControl('countryControl', new UntypedFormControl({ value: '', disabled: isDisabled }));
      if (fields.country?.required) {
        formGroup.controls.countryControl.setValidators([Validators.required]);
      }
    }
    if (fields?.currency?.display) {
      formGroup.addControl('currencyControl', new UntypedFormControl({ value: 'USD', disabled: isDisabled }, { updateOn: 'change' }));
      if (fields.currency?.required) {
        formGroup.controls.currencyControl.setValidators([Validators.required]);
      }
    }
    if (fields?.exchangeRate?.display) {
      formGroup.addControl('exchangeRateControl', new UntypedFormControl({ value: 1, disabled: isDisabled }, GreaterThanZeroValidator));
      if (fields.exchangeRate?.required) {
        formGroup.controls.exchangeRateControl.setValidators([Validators.required, GreaterThanZeroValidator]);
      }
    }
    if (fields?.cod?.display) {
      formGroup.addControl('codControl', new UntypedFormControl({ value: null, disabled: isDisabled }));
      if (fields.cod?.required) {
        formGroup.controls.codControl.setValidators([Validators.required]);
      }
    }
    if (fields?.status?.display) {
      formGroup.addControl('statusControl', new UntypedFormControl({ value: '', disabled: isDisabled }));
      if (fields.status?.required) {
        formGroup.controls.statusControl.setValidators([Validators.required]);
      }
    }
    if (fields?.latitude?.display) {
      formGroup.addControl('latitudeControl', new UntypedFormControl({ value: null, disabled: isDisabled }));
      if (fields.latitude?.required) {
        formGroup.controls.latitudeControl.setValidators([Validators.required]);
      }
    }
    if (fields?.longitude?.display) {
      formGroup.addControl('longitudeControl', new UntypedFormControl({ value: null, disabled: isDisabled }));
      if (fields.longitude?.required) {
        formGroup.controls.longitudeControl.setValidators([Validators.required]);
      }
    }
    if (fields?.program?.display) {
      formGroup.addControl('programControl', new UntypedFormControl({ value: null, disabled: isDisabled }));
      if (fields.program?.required) {
        formGroup.controls.programControl.setValidators([Validators.required]);
      }
    }

    return formGroup;
  }

  public setFormValues(formGroup: UntypedFormGroup, project: Project, cod: Date, readOnly: boolean, specificationFormRule: SpecificationFormRule) {
    const fields = specificationFormRule?.fields;

    formGroup.controls.projectTypeControl.setValue(project.projectType, {emitEvent: false});
    formGroup.controls.projectTypeControl.disable({emitEvent: false});

    if (fields?.name?.display) {
      formGroup.controls.nameControl.setValue(project.name, {emitEvent: false});
    }
    if (fields?.description?.display) {
      // Description not needed here
    }
    if (fields?.postalCode?.display) {
      formGroup.controls.postalCodeControl.setValue(project.postalCode, {emitEvent: false});
    }
    if (fields?.address1?.display) {
      formGroup.controls.address1Control.setValue(project.address1, {emitEvent: false});
    }
    if (fields?.address2?.display) {
      formGroup.controls.address2Control.setValue(project.address2, {emitEvent: false});
    }
    if (fields?.city?.display) {
      formGroup.controls.cityControl.setValue(project.city, {emitEvent: false});
    }
    if (fields?.state?.display) {
      formGroup.controls.regionControl.setValue(project.region ? project.region.id : null, {emitEvent: false});
    }
    if (fields?.country?.display) {
      formGroup.controls.countryControl.setValue(project.country ? project.country.id : null, {emitEvent: false});
    }
    if (fields?.currency?.display) {
      // default is USD
      if (!project.alternateCurrency) {
        formGroup.controls.currencyControl.setValue('USD', {emitEvent: false});
      } else {
        formGroup.controls.currencyControl.setValue(project.alternateCurrency, {emitEvent: false});
      }
    }
    if (fields?.exchangeRate?.display) {
      // USD = rate is 1
      if (formGroup.controls.currencyControl.value === 'USD') {
        formGroup.controls.exchangeRateControl.setValue(1, {emitEvent: false});
        formGroup.controls.exchangeRateControl.disable({emitEvent: false});
      } else {
        formGroup.controls.exchangeRateControl.setValue(project.exchangeRate, {emitEvent: false});
      }
    }
    if (fields?.cod?.display) {
      formGroup.controls.codControl.setValue(cod, {emitEvent: false});
    }
    if (fields?.status?.display) {
      formGroup.controls.statusControl.setValue(project.status, {emitEvent: false});
    }
    if (fields?.latitude?.display) {
      formGroup.controls.latitudeControl.setValue(project.latitude, {emitEvent: false});
    }
    if (fields?.longitude?.display) {
      formGroup.controls.longitudeControl.setValue(project.longitude, {emitEvent: false});
    }
    if (fields?.program?.display) {
      formGroup.controls.programControl.setValue(project.program ? project.program.id : null, {emitEvent: false});
    }

    formGroup.controls.descriptionControl.markAsUntouched();
    formGroup.controls.descriptionControl.markAsPristine();

    if (readOnly) {
      formGroup.disable({emitEvent: false});
    }

  }

  public getFormValues(formGroup: UntypedFormGroup, specificationFormRule: SpecificationFormRule, oldProject?: Project): Project {
    const project = new Project({});
    if (oldProject.id) {
      project.id = oldProject.id;
    }

    const fields = specificationFormRule?.fields;

    project.projectType = formGroup.controls.projectTypeControl.value;

    if (fields?.name?.display) {
      project.name = this._sanitizer.sanitize(SecurityContext.HTML, formGroup.controls.nameControl.value);
    }
    if (fields?.description?.display) {
      if (formGroup.controls.descriptionControl.value.textControl) {
        project.description = formGroup.controls.descriptionControl.value.textControl;
      } else {
        project.description = oldProject.description;
      }
    }
    if (fields?.postalCode?.display) {
      project.postalCode = formGroup.controls.postalCodeControl.value;
    }
    if (fields?.address1?.display) {
      project.address1 = formGroup.controls.address1Control.value;
    }
    if (fields?.address2?.display) {
      project.address2 = formGroup.controls.address2Control.value;
    }
    if (fields?.city?.display) {
      project.city = formGroup.controls.cityControl.value;
    }
    if (fields?.state?.display) {
      const regionId = formGroup.controls.regionControl.value;
      if (regionId && regionId !== '') {
        project.region = this._regions.find(region => region.id === regionId);
      }
    }
    if (fields?.country?.display) {
      const countryId = formGroup.controls.countryControl.value;
    if (countryId && countryId !== '') {
      project.country = this._countries.find(country => country.id === countryId);
    }
    }
    if (fields?.currency?.display) {
      project.alternateCurrency = formGroup.controls.currencyControl.value;
    }
    if (fields?.exchangeRate?.display) {
      project.exchangeRate = formGroup.controls.exchangeRateControl.value;
    }
    if (fields?.cod?.display) {
      project.cod = new Date(formGroup.controls.codControl.value);
    }
    if (fields?.status?.display) {
      const statusId = formGroup.controls.statusControl.value;
      if (statusId && statusId !== '') {
        const type = this._projectStatusTypes.find(status => status.id === statusId);
        project.status = type ? type.id : undefined;
      }
    }
    if (fields?.latitude?.display) {
      project.latitude = formGroup.controls.latitudeControl.value;
    }
    if (fields?.longitude?.display) {
      project.longitude = formGroup.controls.longitudeControl.value;
    }
    if (fields?.program?.display) {
      const programId = formGroup.controls.programControl.value;
      if (programId && programId !== '') {
        project.program = this._programs.find(program => program.id === programId);
      }
    }

    return project;
  }
}
