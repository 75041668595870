import { BaseModel } from '@shared/base.model';
import { VerificationRule } from '@shared/components/files/shared/verification-rules.enum';
import { Organization } from '@shared/models/organization/organization.model';

import { ConnectionsProfile } from './connections-profile.model';
import { ProgramApplicationStageConfiguration } from './program-application-stage-configuration.model';
import { ProgramApplication } from './program-application.model';
import { PROGRAM_MODULE_FLAGS } from './program-module-flags.enum';
import { ProgramPermissions } from './program-permissions.model';
import { ProgramPreQualificationCriteria } from './program-pre-qualification-criteria.model';
import { OrganizationFinancialModel } from '@project/detail/financials/organization-financial-model/organization-financial-model.model';

export enum PROGRAM_TYPE {
  PUBLIC_NO_PRE_QUALIFICATION = 'PUBLIC_NO_PRE_QUALIFICATION',
  PUBLIC_PRE_QUALIFICATION = 'PUBLIC_PRE_QUALIFICATION',
  INVITE_ONLY_NO_PRE_QUALIFICATION = 'INVITE_ONLY_NO_PRE_QUALIFICATION',
  INVITE_ONLY_PRE_QUALIFICATION = 'INVITE_ONLY_PRE_QUALIFICATION'
}

export enum ProgramSubmissionType {
  PROJECT = 'PROJECT',
  C_I = 'C_I'
}

export enum FinancialModelTemplate {
  ODYSSEY = 'ODYSSEY',
}

export class Program extends BaseModel<Program> {
  analyticsDataRequirementFlag: PROGRAM_MODULE_FLAGS;
  beginDateTime: Date;
  bidReviewDateTime: Date;
  connectionsProfile: ConnectionsProfile;
  description: string;
  endDateTime: Date;
  financialModel: OrganizationFinancialModel;
  guideUrlParam: string;
  inviteOnly: boolean;
  name: string;
  preQualEndDateTime: Date;
  programApplications: ProgramApplication[];
  programApplicationStageConfigurations: ProgramApplicationStageConfiguration[];
  programPermissions: ProgramPermissions;
  programPreQualificationCriteria: ProgramPreQualificationCriteria[] = [];
  projectApplicationFormRequired: boolean;
  projectEvalConfigId: string;
  projectGroupBusinessPlanFormRequired: boolean;
  projectGroupChecklistFormRequired: boolean;
  requirePreQualification: boolean;
  sponsor: Organization;
  submissionType: ProgramSubmissionType;
  verificationRule: VerificationRule;

  connectionsModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  costsModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  distributionDesignModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  financialModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  generationDesignModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  html: string;
  loadModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  milestonesModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  revenueModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  sharingModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  trackerModuleRequirementFlag: PROGRAM_MODULE_FLAGS;

  constructor(programInfo: any) {
    super();
    this.id = programInfo.id;
    this.analyticsDataRequirementFlag = programInfo.analyticsDataRequirementFlag;
    this.beginDateTime = programInfo.beginDateTime;
    this.bidReviewDateTime = programInfo.bidReviewDateTime;
    this.connectionsModuleRequirementFlag = programInfo.connectionsModuleRequirementFlag;
    this.connectionsProfile = programInfo.connectionsProfile;
    this.costsModuleRequirementFlag = programInfo.costsModuleRequirementFlag;
    this.description = programInfo.description;
    this.distributionDesignModuleRequirementFlag = programInfo.distributionDesignModuleRequirementFlag;
    this.endDateTime = programInfo.endDateTime;
    this.financialModuleRequirementFlag = programInfo.financialModuleRequirementFlag;
    this.generationDesignModuleRequirementFlag = programInfo.generationDesignModuleRequirementFlag;
    this.guideUrlParam = programInfo.guideUrlParam;
    this.html = programInfo.html;
    this.inviteOnly = programInfo.inviteOnly;
    this.loadModuleRequirementFlag = programInfo.loadModuleRequirementFlag;
    this.milestonesModuleRequirementFlag = programInfo.milestonesModuleRequirementFlag;
    this.name = programInfo.name;
    this.preQualEndDateTime = programInfo.preQualEndDateTime;
    this.programApplications = programInfo.programApplications;
    this.programApplicationStageConfigurations = programInfo.programApplicationStageConfigurations;
    this.programPermissions = programInfo.programPermissions;
    this.projectApplicationFormRequired = programInfo.projectApplicationFormRequired;
    this.projectEvalConfigId = programInfo.projectEvalConfigId;
    this.projectGroupBusinessPlanFormRequired = programInfo.projectGroupBusinessPlanFormRequired;
    this.projectGroupChecklistFormRequired = programInfo.projectGroupChecklistFormRequired;
    this.revenueModuleRequirementFlag = programInfo.revenueModuleRequirementFlag;
    this.sharingModuleRequirementFlag = programInfo.sharingModuleRequirementFlag;
    this.sponsor = new Organization(programInfo.sponsor || {});
    this.submissionType = programInfo.submissionType;
    this.trackerModuleRequirementFlag = programInfo.trackerModuleRequirementFlag;
    this.verificationRule = programInfo.verificationRule;

    if (programInfo.programPreQualificationCriteria) {
      programInfo.programPreQualificationCriteria.forEach(criterion => {
        this.programPreQualificationCriteria.push(new ProgramPreQualificationCriteria(criterion));
      });
    }
    this.requirePreQualification = programInfo.requirePreQualification;
    this.financialModel = programInfo.financialModel;
  }

  get link() {
    return `/oes/finance/programs/detail/${this.id}/`;
  }
}
