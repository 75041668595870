import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserAdminService } from './user-admin.service';
import { User } from '@user/user.model';
import { Organization } from '@shared/models/organization/organization.model';
import { Role } from '@user/role.model';
import { ROLE_TYPE } from '@user/role-type';
import { UserAdminGridSettingService } from './user-admin-grid-setting.service';
import { GridOptions, GridApi, IServerSideGetRowsRequest, ColumnApi, IServerSideDatasource, GridReadyEvent } from 'ag-grid-community';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { take, takeUntil } from 'rxjs/operators';
import { EventService } from '@shared/event.service';
import { RoleAdminService } from 'src/app/analytics/data-integrations/shared/role-admin.service';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectOption } from '@shared/components/dropdown/dropdown.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@user/user.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { OrganizationAdminService } from '@admin/organization/organization-admin.service';
import { PageStatus } from '@shared/ag-grid/component/ag-grid-paginator/page-status';
import { GridState } from '@shared/ag-grid/gird-state';
import { Router } from '@angular/router';

interface RoleChanges {
  addRoles: Role[],
  removeRoles: Role[],
  hasChanges: () => boolean
};


@Component({
  selector: 'oes-admin-user',
  templateUrl: 'user-admin.component.html',
  styleUrls: ['user-admin.component.scss']
})

export class UserAdminComponent implements OnInit, OnDestroy {
  @ViewChild(ModalDialogComponent, {static: false}) public createModal: ModalDialogComponent;
  @ViewChild('deleteUserModal', {static: false}) public deleteUserModal: ModalDialogComponent;

  columnApi: ColumnApi;
  currentUser: User;
  defaultGridState: GridState;
  formGroup: UntypedFormGroup;
  gridApi: GridApi;
  gridOptions: GridOptions = {};
  gridState: GridState;
  isAdminPage: boolean;
  loaded = false;
  orgAdmin: boolean = false;
  organizationOptions: SelectOption[] = []; // dropdown
  organizations: Organization[]; // dropdown
  roles: Role[]; // dropdown
  roleType = ROLE_TYPE;
  selectedOrganization: SelectOption[] = [];
  systemAdmin: boolean = false;
  totalItems = 0;

  private gridStateStorageKey: string;
  private ngUnsubscribe: Subject<any> = new Subject();
  private pageStatus: PageStatus = {
    page: 0,
    pageSize: 100
  };
  private selectedUser: User; // to hold all user data

  constructor(public _userAdminGridSettingService: UserAdminGridSettingService,
              private _organizationAdminService: OrganizationAdminService,
              private _userAdminService: UserAdminService,
              private _roleAdminService: RoleAdminService,
              private _router: Router,
              private _translateService: TranslateService,
              private _eventService: EventService,
              private _userService: UserService) {
  }

  private newRoleChanges(): RoleChanges {
    let addRoles: Role[] = [];
    let removeRoles: Role[] = [];
    return {
      addRoles,
      removeRoles,
      hasChanges: () => !!addRoles.length || !!removeRoles.length
    } as RoleChanges;
  }

  ngOnInit() {
    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe((user: User) => {
      this.gridStateStorageKey = this._userAdminGridSettingService.buildGridStateStorageKey('adminUserList', user.id);
      this.currentUser = user;
      this.systemAdmin = user.hasRole(ROLE_TYPE.SYSTEM_ADMIN);
      this.orgAdmin = user.hasRole(ROLE_TYPE.ORGANIZATION_ADMIN);
      this.initForm();
      this.selectedUser = new User({});
      if (this.systemAdmin) {
        this.getOrganizations();
      }
      this.gridOptions = this._userAdminGridSettingService.getGridOptions();
    });
    this.isAdminPage = this._router.url.endsWith('/admin/users');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._userAdminGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._userAdminGridSettingService.buildDefaultGridState();
    this._userAdminGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
    const datasource = this.getServerSideDatasource();
    params.api!.setServerSideDatasource(datasource);
  }

 // ag-grid callback: filter, sort and group
  gridStatusChanged(event) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._userAdminGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
    }
  }

  pageUpdated(pageStatus: PageStatus) {
    if (pageStatus) {
      this.pageStatus = pageStatus;
      this.gridApi.paginationSetPageSize(this.pageStatus.pageSize);
      this.gridApi.refreshServerSide();
    }
  }

  rowDoubleClicked(event) {
    if (event?.data) {
      this.selectedUser = event.data;
      this.setValue(event.data);
      if (this.systemAdmin || this.orgAdmin) {
        this.resetFormArray();
        this.selectedOrganization = [{
          id: event.data.organization.id,
          name: event.data.organization.name
        }];
        this.formGroup.controls['selectOption'].disable({emitEvent: false});
        this.updateRolesOption();
      }
      this.createModal.show();
    }
  }

  cellClicked(event) {
    if (event?.column?.colId === 'passwordReset' &&
        event.data?.id !== '') {
      this.resetPassword(event.data.id);
    }
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList: {
        const processCellCallback = (params: any) => {
          if (typeof params?.value === 'object' && params.value.length > 0) {
            const keys = Object.keys(params.value[0]);
            if ( keys.includes('name')) {
              const roles = params.value.map(item => item.name);
              return roles.join('|');
            }
          }
          return params.value;
        };
        this._userAdminGridSettingService.exportCsv(this.gridApi, 'Manage All User', true, processCellCallback);
        break;
      }
      case GridSubAction.reload:
        this.gridApi.refreshServerSide();
        break;
      case GridSubAction.clearFilter:
        this._userAdminGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        break;
    }
  }

  cancelCreate() {
    this.selectedOrganization = [];
    this.formGroup.reset();
    this.createModal.hide();
  }

  openDialog() {
    this.formGroup.controls['selectOption'].enable({emitEvent: false});
    this.formGroup.controls['active'].setValue(true, {emitEvent: false});
    this.selectedUser = new User({});

    if (this.systemAdmin || this.orgAdmin) {
      const userRole = this.roles.find(role => role.name === ROLE_TYPE.USER);
      if (userRole) {
        this.selectedUser.roles.push();
      }
      this.updateFormArrayItem(ROLE_TYPE.USER, true, true);
      this.updateRolesOption();

      if (this.systemAdmin) {
        this.updateFormArrayItem(ROLE_TYPE.DATA_ANALYST, false, false);
      }

      if (!this.systemAdmin && this.orgAdmin && this.currentUser.organization.dataAnalyst) {
        this.updateFormArrayItem(ROLE_TYPE.DATA_ANALYST, false, false);
      }
    } else {
      this.inheritRoles();
    }
    this.createModal.show();
  }

  selectionChanged(event) {
    const id = event.length > 0 && event[0].id;
    if (id && this.selectedUser?.organization?.id !== id) {
      this.selectedUser.organization = this.organizations.find(org => org.id === id);
      this.updateRolesOption();
    }
  }

  softDeleteWarning() {
    this.deleteUserModal.show();
  }

  softDeleteUser() {
    this.deleteUserModal.hide();
    this.createModal.hide();
    this._userAdminService.softDeleteUser(this.selectedUser.id).subscribe((user: User) => {
      this.gridApi.refreshServerSide();
      this._eventService.success(this.selectedUser.username + ' ' + this._translateService.instant('success-message.deleted'));
    });
  }

  saveUser() {
    const roleChanges = this.determineRoleChanges();
    this.selectedUser['roles'].push(...roleChanges.addRoles );
    this.selectedUser['roles'] = this.removeRolesFromList(roleChanges.removeRoles, this.selectedUser['roles']);
    if (this.systemAdmin) {
      const organizationId = this.formGroup.controls['selectOption'].value[0];
      const organization = this.organizations.find(item => item.id === organizationId);
      this.selectedUser.organization = organization;
    } else {
      this.selectedUser.organization = this.currentUser.organization;
    }
    this.selectedUser.emailAddress = this.formGroup.controls['emailAddress'].value;
    this.selectedUser.firstName = this.formGroup.controls['firstName'].value;
    this.selectedUser.lastName = this.formGroup.controls['lastName'].value;
    this.selectedUser.active = this.formGroup.controls['active'].value;
    if (this.selectedUser.emailAddress && this.selectedUser.emailAddress !== '') {
      this.selectedUser.username = this.selectedUser.emailAddress;
    }
    this._userAdminService.createUpdate(this.selectedUser)
    .pipe(take(1))
    .subscribe(user => {
      if (user) {
        this._eventService.success(this._translateService.instant('success-message.user-updated'));
        if ((this.systemAdmin || this.orgAdmin) && roleChanges.hasChanges()) {
          this.sendRoleUpdates(user.id, roleChanges);
          this.resetFormArray();
        }
      }
      this.createModal.hide();
      this.gridApi.refreshServerSide();
      this.selectedUser = undefined;
    },
    error => {
      this._eventService.error(this._translateService.instant('error-message.user-updated'));
      this.createModal.hide();
      this.gridApi.refreshServerSide();
      this.selectedUser = undefined;
    });
  }

  resetPassword(userId: string) {
    this._userAdminService.resetPassword(userId)
    .pipe(take(1))
    .subscribe(user => {
      this._eventService.success(this._translateService.instant('success-message.sent-reset-email'));
    });
  }

  private getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {
        const options = this.getHttpOptions(params?.request);
        this._userAdminService.list(options)
          .pipe(take(1))
          .subscribe((response) => {
            this.loaded = true;
            this.totalItems = response.totalElements;
            this.pageStatus.page = response.number;
            let content = response.content;
            if (!this.systemAdmin) {
              content = content.filter(user => !user.emailAddress.includes('@odysseyenergysolutions.com'));
            }
            params.success({
              rowData: content,
              rowCount: response.totalElements
            });
            this.setOverlay(content);
          });
      }
    };
  }

  private initForm() {
    this.formGroup = new UntypedFormGroup({
      selectOption: new UntypedFormControl({value: null, disabled: true}, Validators.required),
      emailAddress: new UntypedFormControl('', {validators: [Validators.required], updateOn: 'blur'}),
      firstName: new UntypedFormControl(''),
      lastName: new UntypedFormControl(''),
      active: new UntypedFormControl(true),
      roles: new UntypedFormArray([])
    });
    if (this.systemAdmin || this.orgAdmin) {
      this.createRoleForms();
      this.formGroup.controls.emailAddress.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(val => {
        this.updateRolesOption();
      });
    }
  }

  private createRoleForms() {
    this.roles = [];
    this._roleAdminService.list()
    .pipe(take(1))
    .subscribe((roles: Role[]) => {
      if (roles?.length > 0) {
        this.roles = roles.sort((a: Role, b: Role) => a.name > b.name ? 1 : -1);
        this.roles.forEach(() => {
          (<UntypedFormArray>this.formGroup.controls.roles).push(new UntypedFormControl(false));
        });
      }
    });
  }

  private resetFormArray() {
    this.roles.forEach((role, index) => {
      if (role.name !== ROLE_TYPE.USER) {
        (<UntypedFormArray>this.formGroup.controls.roles).controls[index].setValue(false, {emitEvent: false});
      }
    });
  }

  private getOrganizations() {
    this._organizationAdminService.list()
    .pipe(take(1))
    .subscribe((organizations: Organization[]) => {
      this.organizations = organizations;
      if (organizations.length > 0) {
        this.organizationOptions = organizations.map(organization => {
          return {
            id: organization.id,
            name: organization.name
          };
        });
      }
    });
  }

  private setOverlay(content: User[]) {
    if (content.length) {
      this.gridApi.hideOverlay();
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  private setValue(user: User) {
    this.formGroup.controls['emailAddress'].setValue(user.emailAddress, {emitEvent: false});
    this.formGroup.controls['firstName'].setValue(user.firstName, {emitEvent: false});
    this.formGroup.controls['lastName'].setValue(user.lastName, {emitEvent: false});
    this.formGroup.controls['active'].setValue(user.active, {emitEvent: false});
  }

  private inheritRoles() {
    const isDeveloper = this.currentUser.roles.some(role => role.name === ROLE_TYPE.DEVELOPER_USER);
    const isInvestor = this.currentUser.roles.some(role => role.name === ROLE_TYPE.FINANCE_USER);
    this.selectedUser['addRoles'] = [];
    this.selectedUser['addRoles'].push(ROLE_TYPE.USER);
    if (isInvestor) {
      this.selectedUser['addRoles'].push(ROLE_TYPE.FINANCE_USER);
    } else if (isDeveloper && !isInvestor) {
      this.selectedUser['addRoles'].push(ROLE_TYPE.DEVELOPER_USER);
    }
  }

  private updateFormArrayItem(roleType: ROLE_TYPE, value: any, disabled: boolean = false) {
    this.roles.forEach((role: Role, i: number) => {
      if (role.name === roleType) {
        (<UntypedFormArray>this.formGroup.controls.roles).controls[i].setValue(value, {emitEvent: false});
        if (disabled) {
          (<UntypedFormArray>this.formGroup.controls.roles).controls[i].disable({emitEvent: false});
        } else {
          (<UntypedFormArray>this.formGroup.controls.roles).controls[i].enable({emitEvent: false});
        }
      }
    });
  }

  private updateRolesOption() {
    if (this.selectedUser) {
      if (this.formGroup.controls?.emailAddress?.value?.includes('@odysseyenergysolutions.com')) {
        this.updateFormArrayItem(ROLE_TYPE.SYSTEM_ADMIN, false, false);
      } else {
        this.updateFormArrayItem(ROLE_TYPE.SYSTEM_ADMIN, false, true);
      }
      if (this.selectedUser.roles[0]) {
        this.selectedUser.roles.forEach(role => {
          this.updateFormArrayItem(role.name, true, false);
        });
      }
    }
  }

  // determine which roles
  private determineRoleChanges(): RoleChanges {
    let previousRoles = Object.assign([], this.selectedUser['roles']);
    const roleButtons = (<UntypedFormArray>this.formGroup.controls.roles).controls;
    const roleChanges = this.newRoleChanges();
    return roleButtons.reduce((accum, roleButton, roleButtonIdx) => {
      let isRoleSelected = roleButton.value;
      let role = this.roles[roleButtonIdx];
      let isPreviouslySelected = previousRoles.some(previousRole => previousRole.id === role.id);
      if (isRoleSelected && !isPreviouslySelected) {
        accum.addRoles.push(role);
      }
      if (!isRoleSelected && isPreviouslySelected) {
        accum.removeRoles.push(role);
      }
      return accum;
    }, roleChanges);
  }

  private removeRolesFromList(rolesToRemove: Role[], roles: Role[]): Role[] {
    return rolesToRemove.reduce((accum, roleToRemove) =>
        accum.filter(existingRole => existingRole.id !== roleToRemove.id),
        roles);
  }

  private sendRoleUpdates(userId: string, roleChanges) {
    let subscribers: Observable<any>[] = [];
    if (roleChanges.addRoles.length > 0) {
      const roleNamesToAdd = roleChanges.addRoles.map(roleToAdd => roleToAdd.name);
      subscribers.push(this._userAdminService.addRoles(userId, roleNamesToAdd).pipe(take(1)));
    }
    if (roleChanges.removeRoles.length > 0) {
      const roleNamesToRemove = roleChanges.removeRoles.map(roleToRemove => roleToRemove.name);
      subscribers.push(this._userAdminService.removeRoles(userId, roleNamesToRemove).pipe(take(1)));
    }

    if (subscribers?.length > 0) {
      forkJoin(subscribers)
      .pipe(take(1))
      .subscribe(results => {
        this.createModal.hide();
        this._eventService.success(this._translateService.instant('success-message.updated-role'));
        this.gridApi.refreshServerSide();
      },
      error => {
        this._eventService.error(this._translateService.instant('error-message.updated-role'));
        this.gridApi.refreshServerSide();
      });
    }
  }

  private getHttpOptions(request: IServerSideGetRowsRequest) {
    const options = this.getDefaultHttpOptions();
    this.storeGridState();
    return this._userAdminGridSettingService.constructOptionsSortAndfilter(options, request);
  }

  private getDefaultHttpOptions() {
    return {
      params: {
        size: this.pageStatus.pageSize,
        page: this.pageStatus.page
      }
    };
  }
}
