<details #details>
  <summary (click)="toggle($event)" #summary>
    <div class="chev-bg">
      <img class="chevron" src="assets/icons/line-icons/shared/black/chev-right.svg" />
    </div>
    <div class="summary-text">
      <ng-container *ngTemplateOutlet="title"></ng-container>
    </div>
  </summary>
  <div class="content" #contentContainer>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</details>
