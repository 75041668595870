<div class="project-action">

  <!-- Create project(s) -->
  <div class="create-project">
    <div dropdown class="dropdown-container">
      <button
        class="btn btn-primary"
        data-testid="create-project-button"
        dropdownToggle>
        {{'project-list.create-projects.title' | translate}}
        <span class="arrow-down-icon">
          <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.5L6 6.5L11 1.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </button>
      <ul class="dropdown-menu dropdown-menu-right" data-testid="create-project-dropdown" role="menu">
        <li role="menuitem">
          <a class="dropdown-item" (click)="newProject(projectType.minigrid)">
            {{'project-list.create-projects.create-minigrid' | translate}}
          </a>
        </li>
        <li role="menuitem">
          <a class="dropdown-item" (click)="newProject(projectType.ci)">
            {{'project-list.create-projects.create-ci' | translate}}
          </a>
        </li>
        <ng-container *ngIf="isAdminUser()">
          <li role="menuitem">
            <a (click)="emitInstallSampleProject()" class="dropdown-item" data-testid="install-sample-button">
              {{'project-list.action.install-sample.title' | translate}}
              <div class="description">{{'project-list.action.install-sample.description' | translate}}</div>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <div class="list-header d-flex flex-row justify-content-between align-items-center flex-wrap">
    <div class="d-flex flex-row align-items-center flex-wrap">
      <div class="action-buttons btn-group"
            data-testid="action-buttons"
            [tooltip]="selectedProjectNames == null || selectedProjectNames?.length == 0 || !isDeveloper() ? ActionTipContent : null"
            placement="bottom"
            container="body">
        <!-- Create Portfolio -->
        <div class="action-button">
          <button type="button"
                  class="btn btn-primary action-button-left create-portfolio-button"
                  data-testid="create-portfolio-button"
                  (click)="emitSwitchView(view.PORTFOLIO)"
                  tooltip="{{'project-list.action.portfolio.button' | translate}}"
                  placement="top"
                  [disabled]="selectedProjectNames == null || selectedProjectNames?.length == 0 || (!isDeveloper() && !isSysAdmin())">
            <div></div> <!-- This div here intentionally to preserve tooltip behavior from above.  Do not remove. -->
          </button>
        </div>
        <!-- Bulk Edit Project Data -->
        <div dropdown
            class="action-button"
            [isDisabled]="selectedProjectNames == null || selectedProjectNames?.length == 0">
          <button type="button"
                  dropdownToggle
                  data-testid="bulk-edit-button"
                  class="btn btn-primary bulk-edit-button"
                  tooltip="{{'project-list.action.manage-data.button' | translate}}"
                  placement="top"
                  [disabled]="selectedProjectNames == null || selectedProjectNames?.length == 0">
            <div></div> <!-- This div here intentionally to preserve tooltip behavior from above.  Do not remove. -->
          </button>
          <ul data-testid="bulk-edit-dropdown" class="dropdown-menu dropdown-menu-left" role="menu" *dropdownMenu>
            <h4>
              {{'project-list.action.manage-data.title' | translate}}
            </h4>
            <li role="menuitem">
              <a (click)="emitSwitchView(view.TARIFF)" class="dropdown-item">
                {{'project-list.action.manage-data.revenue' | translate}}</a>
            </li>
          </ul>
        </div>
        <!-- Download Analytics -->
        <div dropdown
            class="action-button"
            [isDisabled]="selectedProjectNames == null || selectedProjectNames?.length == 0">
          <button type="button"
                  dropdownToggle
                  data-testid="download-analytics-button"
                  class="btn btn-primary download-analytics-button"
                  tooltip="{{'project-list.action.download-analytics.button' | translate}}"
                  placement="top"
                  [disabled]="selectedProjectNames == null || selectedProjectNames?.length == 0">
            <div></div> <!-- This div here intentionally to preserve tooltip behavior from above.  Do not remove. -->
          </button>
          <ul class="dropdown-menu dropdown-menu-left" data-testid="download-analytics-dropdown" role="menu" *dropdownMenu>
            <h4>
              {{'project-list.action.download-analytics.title' | translate}}
            </h4>
            <li role="menuitem">
              <a (click)="downloadFinancialOverviewReport()" class="dropdown-item">
                {{'project-list.action.download-analytics.project-overview' | translate}}
                <div class="description">{{'project-list.action.download-analytics.financial-description' | translate}}</div>
              </a>
            </li>
            <li role="menuitem">
              <a (click)="downloadCustomerSummaryReport()" class="dropdown-item">
                {{'project-list.action.download-analytics.customer-summary' | translate}}
                <div class="description">{{'project-list.action.download-analytics.customer-description' | translate}}</div>
              </a>
            </li>
          </ul>
        </div>
        <!-- Copy Project(s) -->
        <div class="action-button">
          <button type="button"
                  class="btn btn-primary copy-projects-button"
                  data-testid="copy-projects-button"
                  (click)="emitCopyProjects()"
                  tooltip="{{'project-list.action.copy.button' | translate}}"
                  placement="top"
                  [disabled]="selectedProjectNames == null || selectedProjectNames?.length == 0">
            <div></div> <!-- This div here intentionally to preserve tooltip behavior from above.  Do not remove. -->
          </button>
        </div>
        <!-- Export Financial Model -->
        <div class="action-button">
          <button type="button"
                  class="btn btn-primary export-models-button"
                  (click)="emitExportProjectFinancials()"
                  data-testid="export-models-button"
                  tooltip="{{'project-list.action.export.button' | translate}}"
                  placement="top"
                  [disabled]="selectedProjectNames == null || selectedProjectNames?.length == 0 || selectedProjectNames?.length > 25">
            <div></div> <!-- This div here intentionally to preserve tooltip behavior from above.  Do not remove. -->
          </button>
        </div>
        <!-- Delete Project(s) -->
        <div class="action-button">
          <button type="button"
                class="btn btn-primary delete-projects-button"
                (click)="emitDeleteProjects()"
                data-testid="delete-projects-button"
                tooltip="{{'project-list.action.delete.button' | translate}}"
                placement="top"
                [disabled]="selectedProjectNames == null || selectedProjectNames?.length == 0">
            <div></div> <!-- This div here intentionally to preserve tooltip behavior from above.  Do not remove. -->
          </button>
        </div>
      </div>
    </div>

    <!-- List/Map toggle -->
    <div class="ml-2">
      <oes-view-toggle-button [mapView]="true">
      </oes-view-toggle-button>
    </div>
  </div>
</div>

<ng-template #ActionTipContent>{{'project-list.selected-projects.message' | translate}}</ng-template>

