
import {concat as observableConcat} from 'rxjs';

import {toArray, take, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {FinanceConnectionStatusUpdate} from './finance-connection-status-update.model';
import { FinanceConnection } from './finance-connection.model';
import { Observable } from 'rxjs';
import { CONNECTION_STATUS } from '@shared/connection-statuses';
import { environment } from '@environments/environment';
import { Project } from '@project/shared/project.model';
import { ProjectGroupService } from '@shared/services/project-group.service';
import { ProjectConnectionService } from '@project/shared/project-connection.service';
import { ProjectConnection } from '@project/shared/project-connection.model';
import { ProjectConnectionStatus, ProjectConnectionInteractionService } from '@project/detail/sharing/shared/project-connection-interaction.service';
import { User } from '@user/user.model';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';

@Injectable()
export class FinanceConnectionStatusUpdateService extends BaseRestApiService {
  private projectGroups = environment.serverUri + `api/${environment.apiVersion}/project-groups`;
  private connectionStatusMap = new Map();
  private projectConnectionStatus = new ProjectConnectionStatus();

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _projectGroupService: ProjectGroupService,
              private _projectConnectionService: ProjectConnectionService,
              private _projectConnectionInteractionService: ProjectConnectionInteractionService) {
    super(_restApiWrapperService);
    this.connectionStatusMap
      .set('level2DateTime', CONNECTION_STATUS[CONNECTION_STATUS.PERMIT_LEVEL_2])
      .set('level3DateTime', CONNECTION_STATUS[CONNECTION_STATUS.PERMIT_LEVEL_3])
      .set('level4DateTime', CONNECTION_STATUS[CONNECTION_STATUS.PERMIT_LEVEL_4])
      .set('level5DateTime', CONNECTION_STATUS[CONNECTION_STATUS.PERMIT_LEVEL_5]);
  }

  private getLevels(projectGroupId: string, financeConnectionId: string) {
    const financeConnection = new FinanceConnection({});
    const regex = new RegExp('level([2-9]|[1-9]\\d\+)DateTime');
    const properties = Object.getOwnPropertyNames(financeConnection);
    let levels = properties.filter((property: string) => regex.test(property));
    levels = levels.map(level => this.connectionStatusMap.get(level));
    return levels;
  }

  public createUpdate(projectGroupId: string, financeConnectionStatusUpdate: FinanceConnectionStatusUpdate): Observable<FinanceConnection> {
    if (financeConnectionStatusUpdate.id) {
      return this.put<any>(
        `${this.projectGroups}/${projectGroupId}/finance/connections/${financeConnectionStatusUpdate.id}`,
        JSON.stringify(financeConnectionStatusUpdate)
      )
      .pipe(
        map(result => new FinanceConnection(result))
      );
    }
    return this.post<any>(
      `${this.projectGroups}/${projectGroupId}/finance/connections`,
      JSON.stringify(financeConnectionStatusUpdate)
      )
      .pipe(
        map(result => new FinanceConnection(result))
      );
  }

  releaseToInvestor(projectGroupId: string, financeConnection: FinanceConnection, currentUser: User) {
    this.shareProjectsWithInvestor(projectGroupId, financeConnection, currentUser);
    return this._releaseToInvestor(projectGroupId, financeConnection.id);
  }

  private _releaseToInvestor(projectGroupId: string, financeConnectionId: string) {
    const levels = this.getLevels(projectGroupId, financeConnectionId);
    const financeConnectionStatusUpdates$: Observable<FinanceConnectionStatusUpdate | FinanceConnection>[] = [];
    levels.push(CONNECTION_STATUS[CONNECTION_STATUS.ACCEPT]);
    levels.forEach(level => {
      financeConnectionStatusUpdates$.push(this._save(projectGroupId, financeConnectionId, level));
    });
    return observableConcat(...financeConnectionStatusUpdates$).pipe(toArray());
  }

  private _save(projectGroupId, financeConnectionId, level): Observable<FinanceConnectionStatusUpdate> {
    if (financeConnectionId) {
      return this.put<any>(`${this.projectGroups}/${projectGroupId}/finance/connections/${financeConnectionId}`,
        new FinanceConnectionStatusUpdate({
          id: financeConnectionId,
          financeConnectionStatus: level
        })
      );
    }
     return this.post<any>(`${this.projectGroups}/${projectGroupId}/finance/connections`,
        new FinanceConnectionStatusUpdate({
          id: financeConnectionId,
          financeConnectionStatus: level
        })
      );
  }

  shareProjectsWithInvestor(projectGroupId: string, financeConnection: FinanceConnection, currentUser: User) {
    this._projectGroupService.projects(projectGroupId)
    .pipe(take(1))
    .subscribe((projects: Project[]) => {
      if (projects && projects.length) {
        projects.forEach(project => {
          this.shareProject(project.id, financeConnection.financeOrganization.id, currentUser);
        });
      }
    });
  }

  private shareProject(projectId: string, recipientId: string, currentUser: User) {
    this._projectConnectionService.shareProject(
      projectId,
      recipientId,
      new ProjectConnection({
        shareCostModelData: true,
        shareCustomerData: true,
        shareDistributionDesignData: true,
        shareFinancialModelData: true,
        shareGenerationDesignData: true,
        shareProjectSpecificationData: true,
        shareProjectSummaryData: true,
        shareRevenueModelData: true,
        shareSystemLoadData: true,
        shareOperatingData: true,
        shareDatasetData: true,
        shareAnalyticsData: true,
        shareMilestoneData: true
      })
    ).subscribe((res) => {});
  }
}
