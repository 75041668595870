import {BaseModel} from '@shared/base.model';
import {CurrencyPipe} from '@angular/common';

export class ProjectFinancialModelRun extends BaseModel<ProjectFinancialModelRun> {
  adjustedCapexOutput: string;
  anchorArpuOutput: string;
  avgDSCROutput: string;
  capexPerConnectionOutput: string;
  commercialArpuOutput: string;
  equityIRROutput: string;
  equityPaybackOutput: string;
  id: string;
  irrOutput: string;
  lastRunBy: string;
  lcoeOutput: string;
  minDSCROutput: string;
  npvToEquityOutput: string;
  operatingMarginOutput: string;
  paybackYearOutput: string;
  productiveArpuOutput: string;
  projectCapexOutput: string;
  publicArpuOutput: string;
  residentialArpuOutput: string;
  totalExpensesOutput: string;
  totalKwhSoldOutput: string;
  totalRevenueOutput: string;
  totalCapexGrantOutput: string;
  totalDebtOutput: string;
  totalPerConnectionGrantOutput: string;
  totalEquityInvestmentOutput: string;

  constructor(projectFinancialModelRunInfo: any) {
    super();
    this.adjustedCapexOutput = this.adjustType(projectFinancialModelRunInfo.adjustedCapexOutput, 'currency');
    this.anchorArpuOutput = this.adjustType(projectFinancialModelRunInfo.anchorArpuOutput, 'currency');
    this.avgDSCROutput = this.adjustType(projectFinancialModelRunInfo.avgDSCROutput, 'number');
    this.capexPerConnectionOutput = this.adjustType(projectFinancialModelRunInfo.capexPerConnectionOutput, 'currency');
    this.commercialArpuOutput = this.adjustType(projectFinancialModelRunInfo.commercialArpuOutput, 'currency');
    this.equityIRROutput = this.adjustType(projectFinancialModelRunInfo.equityIRROutput, 'percent');
    this.equityPaybackOutput = this.adjustType(projectFinancialModelRunInfo.equityPaybackOutput, 'years');
    this.id = this.adjustType(projectFinancialModelRunInfo.id, '');
    this.irrOutput = this.adjustType(projectFinancialModelRunInfo.irrOutput, 'percent');
    this.lastRunBy = this.adjustType(projectFinancialModelRunInfo.lastRunBy, '');
    this.lcoeOutput = this.adjustType(projectFinancialModelRunInfo.lcoeOutput, 'perKWh');
    this.minDSCROutput = this.adjustType(projectFinancialModelRunInfo.minDSCROutput, 'number');
    this.npvToEquityOutput = this.adjustType(projectFinancialModelRunInfo.npvToEquityOutput, 'currency');
    this.operatingMarginOutput = this.adjustType(projectFinancialModelRunInfo.operatingMarginOutput, 'currency');
    this.paybackYearOutput = this.adjustType(projectFinancialModelRunInfo.paybackYearOutput, 'years');
    this.productiveArpuOutput = this.adjustType(projectFinancialModelRunInfo.productiveArpuOutput, 'currency');
    this.projectCapexOutput = this.adjustType(projectFinancialModelRunInfo.projectCapexOutput, 'currency');
    this.publicArpuOutput = this.adjustType(projectFinancialModelRunInfo.publicArpuOutput, 'currency');
    this.residentialArpuOutput = this.adjustType(projectFinancialModelRunInfo.residentialArpuOutput, 'currency');
    this.totalExpensesOutput = this.adjustType(projectFinancialModelRunInfo.totalExpensesOutput, 'currency');
    this.totalKwhSoldOutput = this.adjustType(projectFinancialModelRunInfo.totalKwhSoldOutput, 'kWh');
    this.totalRevenueOutput = this.adjustType(projectFinancialModelRunInfo.totalRevenueOutput, 'currency');
    this.totalCapexGrantOutput = this.adjustType(projectFinancialModelRunInfo.totalCapexGrantOutput, 'currency');
  this.totalDebtOutput = this.adjustType(projectFinancialModelRunInfo.totalDebtOutput, 'currency');
  this.totalPerConnectionGrantOutput = this.adjustType(projectFinancialModelRunInfo.totalPerConnectionGrantOutput, 'currency');
  this.totalEquityInvestmentOutput = this.adjustType(projectFinancialModelRunInfo.totalEquityInvestmentOutput, 'currency');
  }

  adjustType(value: any, type) {
    const isText = isNaN(value);

    if (value === 'null') {
      return '—';
    } else if (type === '' || isText) {
      return value;
    }

    switch (type) {
      case 'currency':
      return new CurrencyPipe('en-Us').transform(value, 'USD', 'symbol', '1.2-2');

      case 'percent':
      return this.setDec(value, 2, 100) + '%';

      case 'years':
      return this.setDec(value, 0) + ' years';

      case 'kWh':
      return this.setDec(value) + ' kWh';

      case 'perKWh':
      return new CurrencyPipe('en-Us').transform(value, 'USD', 'symbol', '1.2-2') + ' / kWh';

      case 'number':
      return this.setDec(value);
    }
  }

  setDec(value, decimal = 2, multiple = 1) {
    const x = parseFloat(value) * multiple;
    return x.toFixed(decimal).toString();
  }
}
