<ng-container [formGroup]="customersFormGroup">
  <h5 class="bold mb-3">{{'deal-description.customers.general-description.label' | translate}}</h5>
  <oes-quill
    formControlName="customerTypeDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.customerTypeDescription"
    [placeholder]="'deal-description.customers.general-description.placeholder' | translate">
  </oes-quill>
  <h5 class="bold mb-3 mt-4">{{'deal-description.customers.describe-relations.label' | translate}}</h5>
  <oes-quill
    formControlName="customerRelationsDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.customerRelationsDescription"
    [placeholder]="'deal-description.customers.describe-relations.placeholder' | translate">
  </oes-quill>
  <h5 class="bold mb-3 mt-4">{{'deal-description.customers.describe-education.label' | translate}}</h5>
  <oes-quill
    formControlName="customerEducationDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.customerEducationDescription"
    [placeholder]="'deal-description.customers.describe-education.placeholder' | translate">
  </oes-quill>
  <h5 class="bold mb-3 mt-4">{{'deal-description.customers.describe-pricing.label' | translate}}</h5>
  <oes-quill
    formControlName="customerPricingDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.customerPricingDescription"
    [placeholder]="'deal-description.customers.describe-pricing.placeholder' | translate">
  </oes-quill>
</ng-container>
