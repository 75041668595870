import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { GridState } from '@shared/ag-grid/gird-state';
import { ActionType } from '@shared/components/files/shared/action-type.enum';
import { DOCUMENT_TAG_TYPE } from '@shared/components/files/shared/document-tag-type.enum';
import { DownloadService } from '@shared/components/files/shared/download.service';
import { FolderService } from '@shared/components/files/shared/folder.service';
import { FilesActions } from '@shared/components/files/shared/models/action.model';
import { DocTag } from '@shared/components/files/shared/models/doc-tag.model';
import { TagType } from '@shared/components/files/shared/models/tag-type.model';
import { EventService } from '@shared/event.service';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { ColumnApi, GridOptions } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ActionService } from '../shared/action.service';
import { DocumentDataService } from '../shared/document-data.service';
import { FilesService } from '../shared/files.service';
import { AssociationAddComponent } from './action-components/association/add/add.component';
import { AssociationDeleteComponent } from './action-components/association/delete/delete.component';
import { CopyComponent } from './action-components/copy/copy.component';
import { CreateFolderComponent } from './action-components/create-folder/create-folder.component';
import { DeleteComponent } from './action-components/delete/delete.component';
import { EditNameComponent } from './action-components/edit-name/edit-name.component';
import { TagAddComponent } from './action-components/tag/add/add.component';
import { TagDeleteComponent } from './action-components/tag/delete/delete.component';
import { UploaderComponent } from './action-components/uploader/uploader.component';
import { FilesGridSettingService } from './files-grid-setting.service';

@Component({
  selector: 'oes-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
  providers: [FilesGridSettingService],
})
export class FilesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() tagTypes: TagType[];
  @Input() accessControl: FilesActions;
  @Input() anotherOrganizationId: string;
  @Input() storageKey = 'files-main-1';
  @Input() removeHeight: string = '227px';
  @Input() autoHeightOverride: boolean = false;
  @Input() uploadExtraMessage: string;
  @Input() reRender: boolean = false;
  @Input() printable = false;
  @Input() events: Observable<any>;
  @Input() title: string;
  @Input() sharedFiles: boolean = false;
  @Input() childDataRoom: boolean = true;

  @Output() uploadProcessCompleted: EventEmitter<File[]> = new EventEmitter<File[]>();

  @ViewChild(CreateFolderComponent, { static: false })
  public createFolder: CreateFolderComponent;
  @ViewChild('copyModalDialog', { static: false })
  public copyModalDialog: CopyComponent;
  @ViewChild('moveModalDialog', { static: false })
  public moveModalDialog: CopyComponent;
  @ViewChild(AssociationAddComponent, { static: false })
  public associationAddDialog: AssociationAddComponent;
  @ViewChild(AssociationDeleteComponent, { static: false })
  public associationDeleteDialog: AssociationDeleteComponent;
  @ViewChild(TagAddComponent, { static: false })
  public tagAddDialog: TagAddComponent;
  @ViewChild(TagDeleteComponent, { static: false })
  public tagDeleteDialog: TagDeleteComponent;
  @ViewChild(DeleteComponent, { static: false })
  public deleteDialog: DeleteComponent;
  @ViewChild(UploaderComponent, { static: false })
  public uploadDialog: UploaderComponent;
  @ViewChild(EditNameComponent, { static: false })
  public editNameDialog: EditNameComponent;

  _tagTypes: TagType[];

  columnApi: ColumnApi;
  dataCompleted = false;
  defaultGridState: GridState;
  docPathList: string[];
  eventsSubscription: Subscription;
  expandState: 'expanded' | 'collapsed' | 'indeterminate' = 'expanded';
  gridApi: any; // keep any to use rowsToDisplay
  gridOptions: GridOptions;
  gridState: GridState;
  organizationId: string;

  private gridStateStorageKey: string;
  private message;
  private ngUnsubscribe: Subject<any> = new Subject();
  private targetOrganizationId: string;
  private user: User;

  constructor(
    private _filesService: FilesService,
    private _documentDataService: DocumentDataService,
    private _userService: UserService,
    public _filesGridSettingService: FilesGridSettingService,
    private _downloadService: DownloadService,
    private _eventService: EventService,
    private _translateService: TranslateService,
    private _actionService: ActionService,
    private _folderService: FolderService
  ) {
    this.subscribeActionButton();
    this._translateService
      .get('data-room.dialogs')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((message) => {
        this.message = message;

        if (!this.title) {
          this.title = message.title;
        }
      });
  }

  ngOnInit() {
    this.gridStateStorageKey = this._filesGridSettingService.buildGridStateStorageKey('myFilesList-shared=' + this.sharedFiles);
    this._filesGridSettingService._gridStateStorageKey = this.gridStateStorageKey;
    this.eventsSubscription = this.events?.subscribe((event: any) => {
      this.reload();
    });
    this._filesService.clear();
    this.setActionControl();
    this._filesGridSettingService.allowDrag = this.accessControl.move;
    this._userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        if (user) {
          this.user = user;
          this.organizationId = user.organization.id;
          this.buildTagTypes();
        }
      });
    this.gridOptions = this._filesGridSettingService.getGridOptions(
      this._tagTypes,
      this.printable,
      this.sharedFiles,
      this.childDataRoom
    );
    this.gridOptions['context'] = {
      gridService: this,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this._tagTypes = this.tagTypes;
    if (this._tagTypes?.length > 0) {
      if (this.reRender) {
        this.loadSwitcher();
      }
    }
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  // callback: reload button
  reload() {
    if (this.gridApi) {
      if (this._tagTypes && this._tagTypes[0].docTagType === DOCUMENT_TAG_TYPE.ORGANIZATION) {
        this.loadDocuments();
      } else {
        this.loadDocumentsByTag();
      }
    }
  }

  // callback: ag-grid
  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    if (this.autoHeightOverride) {
      this.gridApi.setDomLayout('autoHeight');
    }
    this._filesGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._filesGridSettingService.buildDefaultGridState();
    this.loadSwitcher();
  }

  // ag-grid callback: filter, sort and group
  gridStatusChanged(event, type) {
    if (this.gridApi && this.columnApi) {
      this.storeGridState();
      this.getFolders();
      this.selectionChanged('gridStatusChanged');
    }
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._filesGridSettingService.storeGridStateByApis(
        this.storageKey,
        this.gridApi,
        this.columnApi
      );
    }
  }

  selectAll() {
    this.gridApi.selectAll();
    const all = this.gridApi.getSelectedNodes();
    this._filesService.highlighted(all);
    this._filesService.selected(all);
    this.selectionChanged('selectAll');
  }

  clearAll() {
    this.gridApi.deselectAll();
    this._filesService.highlighted([]);
    this._filesService.selected([]);
    this.selectionChanged('clearAll');
  }

  toggleExpand() {
    if (this.expandState === 'expanded') {
      this.collapseAll();
    } else {
      this.expandAll();
      this.expandState = 'expanded';
    }
  }

  expandAll() {
    this.gridApi.expandAll();
    this._filesGridSettingService.saveAllOpenState(true);
    this.expandState = 'expanded';
  }

  collapseAll() {
    this.gridApi.collapseAll();
    this._filesGridSettingService.saveAllOpenState(false);
    this.expandState = 'collapsed';
  }

  // Callback from FileTagFilterComponent
  inComponentSelectionChanged(event: any) {
    this.selectionChanged(event);
  }

  selectionChanged(event) {
    if (this.gridApi && event) {
      if (event?.type === 'rowClicked') {
        event.node.setSelected(!event.node.selected);
      }
      const selectedNodes = [];
      this.gridApi.getSelectedNodes().forEach((node) => {
        node.allLeafChildren.forEach((child) => {
          if (!selectedNodes.includes(child)) {
            selectedNodes.push(child);
          }
        });
      });
      this._filesService.highlighted(this.gridApi.getSelectedNodes());
      this._filesService.selected(selectedNodes);
    }
  }

  onStateChange(event) {
    this._filesGridSettingService.saveCloseState({
      id: event?.node?.id,
      expanded: event?.node?.expanded,
    });
    this.expandState = this._filesGridSettingService.findExpandState();
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList:
        this.exportCsv();
        break;
      case GridSubAction.reload:
        this.reload();
        break;
      case GridSubAction.clearFilter:
        this.columnApi.getColumns().forEach((column) => {
          const colDef = column.getColDef();
          if (colDef.filter === 'fileTagFilterComponent') {
            this.gridOptions.api.getFilterInstance(colDef.field).setModel(null);
          }
        });
        this.gridOptions.api.onFilterChanged();
        this._filesGridSettingService.clearStoredGridState(this.storageKey);
        this.clearAll();
        break;
    }
  }

  onRowDragEnd(event) {
    if (this._actionService.validateDragMove(event)) {
      const selectedNodes = event.node.allLeafChildren;
      const selectedOption = this._actionService.getSelectedOption(event.overNode, this.organizationId);
      const requestItems = this._actionService.getRequestItems(selectedNodes, selectedOption);
      const destinationTagType = this._actionService.getDestinationTagType(selectedOption);
      this._documentDataService
        .moveCopyDocuments(requestItems, destinationTagType, 'move')
        .pipe(take(1))
        .subscribe((results) => {
          this._eventService.success(this.message['moved']);
          this._filesService.clickActionButton(ActionType.completed);
        });
    }
  }

  private loadDocuments() {
    if (this.gridApi) {
      this.clearAll();
    }

    if (this.sharedFiles) {
      this._documentDataService
        .getSharedList(this.targetOrganizationId)
        .pipe(take(1))
        .subscribe((docs: any[]) => {
          this.extractDocs(docs);
        });
    } else if (this.targetOrganizationId) {
      this._documentDataService
        .getList(this.targetOrganizationId)
        .pipe(take(1))
        .subscribe((docs: any[]) => {
          this.extractDocs(docs);
        });
    }
  }

  // Load documents by Tag (for readonly page)
  private loadDocumentsByTag() {
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
    this._filesService.selected([]);
    this._filesService.highlighted([]);

    let tagType: TagType;
    if (this._tagTypes?.length === 1) {
      tagType = this._tagTypes[0];
    } else if (this._tagTypes?.length === 2) {
      tagType = this._tagTypes.filter((item) => {
        return (
          item.docTagType === DOCUMENT_TAG_TYPE.DISTRIBUTION_DESIGN ||
          item.docTagType === DOCUMENT_TAG_TYPE.PRE_QUALIFICATION ||
          item.docTagType === DOCUMENT_TAG_TYPE.MILESTONE ||
          item.docTagType === DOCUMENT_TAG_TYPE.PARTICIPANT_REPORT ||
          item.docTagType === DOCUMENT_TAG_TYPE.DEVELOPER_INFORMATION ||
          item.docTagType === DOCUMENT_TAG_TYPE.VENDOR_INFORMATION
        );
      })[0];
    }
    if (tagType && this.targetOrganizationId) {
      const docTag: DocTag = {
        tagName: tagType.name,
        taggedItemId: tagType.docTagItemId,
        type: tagType.docTagType,
        systemDocumentType: tagType.systemDocumentType,
      };
      this._documentDataService
        .getListByTag(this.targetOrganizationId, docTag)
        .pipe(take(1))
        .subscribe((docs: any[]) => {
          this.extractDocs(docs);
        });
    }
  }

  private extractDocs(docs: any[]) {
    this.gridApi.showLoadingOverlay();
    if (docs?.length > 0) {
      const rowData = this.flatten(docs);
      this.docPathList = rowData.map((doc) => doc.path);
      this._filesService.tagList = this.generateTagList(rowData, 'tags');
      this._filesService.associationList = this.generateTagList(rowData, 'associations');
      this._filesService.programList = this.generateTagList(rowData, 'programTags');
      this._filesService.portfolioList = this.generateTagList(rowData, 'projectGroupTags');
      this._filesService.ciPortfolioList = this.generateTagList(rowData, 'projectSetPortfolioTags');
      this._filesService.projectList = this.generateTagList(rowData, 'projectTags');
      this._filesGridSettingService.loadCloseState();
      this.gridApi.setRowData(rowData);
      this._filesGridSettingService.applyStoredGridState(
        this.storageKey,
        this.defaultGridState
      );
      this.gridApi.hideOverlay();
    } else {
      this.gridApi.setRowData([]);
      this.gridApi.showNoRowsOverlay();
    }
    this.getFolders();
    this.dataCompleted = true;
  }

  private getFolders() {
    if (this.gridApi) {
      const rowData = this.gridApi.getModel();
      this._folderService.folders = rowData.rowsToDisplay
        .filter((item) => {
          return (item.data &&
                  item.data.documentKey &&
                  item.data.documentKey.docType === 'PATH');
        })
        .map((item) => {
          return item.data;
        });
    }
  }

  private flatten(rawData: any[]): any[] {
    const filteredData = rawData.map((item) => {
      return {
        path: item.path ? item.path.replace(/^\//gi, '') : '', // remove the first '/'
        associations: item.documentTags.filter((documentTag) => documentTag.tagType !== DOCUMENT_TAG_TYPE.USER),
        tags: item.documentTags.filter((documentTag) => documentTag.tagType === DOCUMENT_TAG_TYPE.USER),
        size: item.size,
        created: item.created,
        id: item.id,
        documentKey: item.documentKey, // need documentKey for delete folder/file
        formUpload: item.formUpload,
        allowDelete: item.allowDelete,
        allowRename: item.allowRename,
        programTags: item.programTags,
        projectTags: item.projectTags,
        projectGroupTags: item.projectGroupTags,
        projectSetPortfolioTags: item.projectSetPortfolioTags,
        sharedDisplayPath: item.sharedDisplayPath,
      };
    });
    return this.sort(filteredData, 'path');
  }

  private generateTagList(rowData: any, key: string) {
    let tags = [];
    rowData.forEach((data) => {
      let newTags = null;
      if (data && data[key]) {
        newTags = data[key]
          .filter((documentTag) => {
            if (tags && tags.length > 0) {
              // check duplication
              const index = tags.findIndex((item) => item.id === documentTag.id);
              if (index === -1) {
                return documentTag;
              }
            } else {
              return documentTag;
            }
          })
          .map((documentTag) => {
            return { id: documentTag.id,
                     tag: documentTag.tag };
          });
        tags = [...tags, ...newTags];
      }
    });
    return tags;
  }

  private sort(data, key) {
    return data.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  }

  private buildTagTypes() {
    if (!this._tagTypes || this._tagTypes.length === 0) {
      this.setOrganizationId();
      this._tagTypes = [
        { docTagItemId: this.targetOrganizationId,
          docTagType: DOCUMENT_TAG_TYPE.ORGANIZATION },
      ];
    }
  }

  private setOrganizationId() {
    if (
      (this.user && !this.anotherOrganizationId) ||
      this.anotherOrganizationId === ''
    ) {
      this.targetOrganizationId = this.user.organization.id;
    } else {
      this.targetOrganizationId = this.anotherOrganizationId;
    }
  }

  private setActionControl() {
    if (!this.accessControl) {
      if (this.sharedFiles) {
        this.accessControl = new FilesActions({
          copy: false,
          create: false,
          delete: false,
          download: true,
          move: false,
          upload: false,
          tag: false,
          editName: false,
          preview: true,
        });
      } else {
        this.accessControl = new FilesActions({
          copy: true,
          create: true,
          delete: true,
          download: true,
          move: true,
          upload: true,
          tag: true,
          editName: true,
          preview: true,
        });
      }
    }
  }

  private subscribeActionButton() {
    this._filesService.actions$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((actionType: ActionType) => {
        switch (actionType) {
          // TODO: documents/path needs to support tags
          case ActionType.createFolder: {
            this.createFolder?.show();
            break;
          }
          case ActionType.move: {
            this.moveModalDialog?.show();
            break;
          }
          case ActionType.copy: {
            this.copyModalDialog?.show();
            break;
          }
          case ActionType.download: {
            this._downloadService.download(this.targetOrganizationId);
            break;
          }
          case ActionType.upload: {
            this.uploadDialog?.show();
            break;
          }
          case ActionType.delete: {
            this.deleteDialog?.show();
            break;
          }
          case ActionType.associationAdd: {
            this.associationAddDialog?.show();
            break;
          }
          case ActionType.associationDelete: {
            this.associationDeleteDialog?.show();
            break;
          }
          case ActionType.tagAdd: {
            this.tagAddDialog?.show();
            break;
          }
          case ActionType.tagDelete: {
            this.tagDeleteDialog?.show();
            break;
          }
          case ActionType.editName: {
            this.editNameDialog?.show();
            break;
          }
          case ActionType.completed: {
            this.reload();
            break;
          }
          case ActionType.export: {
            this.exportCsv();
            break;
          }
          case ActionType.preview: {
            this._downloadService.preview(this.targetOrganizationId);
            break;
          }
        }
      });
  }

  private loadSwitcher() {
    this.setOrganizationId();
    if (
      this._tagTypes &&
      this._tagTypes[0].docTagType === DOCUMENT_TAG_TYPE.ORGANIZATION
    ) {
      this.loadDocuments();
    } else {
      this.loadDocumentsByTag();
    }
  }

  private exportCsv() {
    const categories = this._tagTypes.map((item) => item.name).join('-');
    const postfix = categories ? '(' + categories + ') ' : '';
    const fileName = 'Files ' + postfix + DateTime.local().toSQLDate();
    this._filesGridSettingService.exportCsv(this.gridApi, fileName, false, (value) => {
        if (value.column.colDef.headerName === this._translateService.instant('data-room.column.tree')) {
          return value.node.data.path;
        }
        switch (value.column.colDef.field) {
          case 'associations':
            return value.node.data ? value.node.data.associations
              .map((document) => document.tag)
              .join('|') : '';
          case 'tags':
            return value.node.data ? value.node.data.tags.map((document) => document.tag).join('|') : '';
          case 'size':
            return value.value ? this._filesGridSettingService.formatFileSize(value) : '0 KB';
          default:
            return value.value;
        }
      }
    );
  }
}
