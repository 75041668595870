import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { ConnectionsProfile } from '@program/shared/connections-profile.model';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { Organization } from '../../shared/models/organization/organization.model';
import { ProjectConnection } from './project-connection.model';
import { ProjectConnectionApiConstants } from './project-connection-api.constant';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectConnectionService extends BaseRestApiService {
  private _projectConnection: ProjectConnection;
  private _projectConnections: ProjectConnection[];

  private connectionSubject = new BehaviorSubject<ProjectConnection>(undefined);
  projectConnection$ = this.connectionSubject.asObservable();


  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  set projectConnection(projectConnection: ProjectConnection) {
    // for subscribers
    this.connectionSubject.next(projectConnection);
    // for accessors
    this._projectConnection = projectConnection;
  }

  get projectConnection(): ProjectConnection {
    return this._projectConnection;
  }

  set projectConnections(projectConnections: ProjectConnection[]) {
    this._projectConnections = projectConnections;
  }

  /**
   * @param projectId: string
   * @param collaboratorId: string
   * @param resetCache: string. 'true': API response will be cached. 'false': the cached data will be deleted and take new data from a server.
   */
  getProjectConnection(projectId: string, collaboratorId: string, resetCache = 'false'): Observable<ProjectConnection> {
    // endpoint requires collaboratorId
    if (collaboratorId) {
      const headers = new HttpHeaders();
      // headers = headers.set('reset-cache', resetCache);
      const searchParams: HttpParams = new HttpParams().set('collaboratorId', collaboratorId);
      const option = {params: searchParams, headers: headers};
      return this.get<ProjectConnection>(ProjectConnectionApiConstants.connection.detail(projectId), option)
        .pipe(map((connection: ProjectConnection) => new ProjectConnection(connection)));
    }
    return new Observable();
  }

  getProjectConnections(projectId: string): Observable<ProjectConnection[]> {
    return this.get<ProjectConnection[]>(ProjectConnectionApiConstants.connection.search(projectId))
      .pipe(
        map((connections: any) => {
          return connections.map((connection: ProjectConnection) => new ProjectConnection(connection));
        })
      );
  }

  getDefaultConnectionsProfile(projectId: string): Observable<ConnectionsProfile> {
    return this.get<ConnectionsProfile>(ProjectConnectionApiConstants.connection.defaultProfile(projectId))
      .pipe(
        map((profile: ConnectionsProfile) => {
          return new ConnectionsProfile(profile);
        })
      );
  }

  shareProject(projectId: string, recipientId: string, permissionGrant: ProjectConnection): Observable<ProjectConnection> {
    permissionGrant.recipient = new Organization({id: recipientId});
    return this.post<ProjectConnection>(ProjectConnectionApiConstants.connection.share(projectId), permissionGrant)
      .pipe(map((connection: ProjectConnection) => new ProjectConnection(connection)));
  }

  updateProjectConnection(projectId: string, permissionGrant: ProjectConnection): Observable<ProjectConnection> {
    return this.put<ProjectConnection>(ProjectConnectionApiConstants.connection.update(projectId, permissionGrant.id), permissionGrant)
      .pipe(map((connection: ProjectConnection) => {
        return new ProjectConnection(connection);
      }));
  }

  unshareProject(projectId: string, projectConnectionId: string): Observable<any> {
    return this.delete<any>(ProjectConnectionApiConstants.connection.delete(projectId, projectConnectionId));
  }


  isSharedOrganization(organizationId: string): boolean {
    if (!this._projectConnections) {
      return false;
    }
    const result = this._projectConnections.find(value => {
      return value.recipient.id === organizationId;
    });
    return result ? true : false;
  }
}
