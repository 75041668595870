<div class="portfolio-list">
  <div class="oes-grid d-flex justify-content-end">
    <ng-container *ngIf="listType; else ProjectListHint">
      <h2>{{ 'portfolio-list.' + listType | translate }}</h2>
    </ng-container>

    <ng-template #ProjectListHint>
      <oes-notify class="hint" [type]="'warning'" [iconName]="'fa-clock-o'">
        <span class="hint-content">
          <span class="font-weight-bold">{{ 'project-list.hint.e' | translate }}</span>
          {{ 'project-list.hint.f' | translate }}
          <span class="font-weight-bold">{{ localTimeZone }}</span>
        </span>
      </oes-notify>
    </ng-template>

    <div class="sub-action-container">
      <oes-grid-sub-action
        *ngIf="gridApi"
        [gridState]="gridState"
        [defaultGridState]="defaultGridState"
        (action)="subAction($event)">
      </oes-grid-sub-action>
    </div>
  </div>
  <div class="ag-grid-header-sticky" oesAgHorizontalScroll oesAgColumnsOffclick>
    <ag-grid-angular #agGrid
      style="width: auto"
      class="grid ag-theme-alpine"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (cellValueChanged)="cellValueChanged($event)"
      (cellClicked)="cellClicked($event)"
      (filterChanged)="gridStatusChanged($event, 'filter')"
      (sortChanged)="gridStatusChanged($event, 'sort')"
      (displayedColumnsChanged)="gridStatusChanged($event, 'column')">
    </ag-grid-angular>
  </div>
</div>
