import { Injectable } from '@angular/core';
import { ColumnConfigFactory } from '@project/shared/grid/column-config-factory';
import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { DateFilterComponent } from '@shared/ag-grid/component/date-filter/date-filter.component';
import { GridState } from '@shared/ag-grid/gird-state';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class OfftakersListGridSettingService extends BaseAgGridSettingsService {
  private column = [];
  private currencyTypes = {};
  private gridConfigurationTypes = [];
  private offtaker = {};
  private stages = {};
  private responses = {};

  public columnValues = [];
  public operatorValues = [];
  public typeValues = [
    'User Specified',
    'Fill from column'
  ];
  public currentGridState: GridState = new GridState();
  public initialGridState: GridState = new GridState();
  public defaultGridState: GridState = new GridState();
  public _gridStateStorageKey: string;
  public _gridApi: GridApi;
  public _columnApi: ColumnApi;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService,
              private _columnConfigFactory: ColumnConfigFactory) {
    super(_agGridServicesWrapperService);
    this.column = _agGridServicesWrapperService._translateService.instant('offtaker.column');
    this.currencyTypes = _agGridServicesWrapperService._translateService.instant('currency-type');
    this.gridConfigurationTypes = _agGridServicesWrapperService._translateService.instant('offtaker.grid-configuration');
    this.offtaker = _agGridServicesWrapperService._translateService.instant('offtaker');
    this.stages = _agGridServicesWrapperService._translateService.instant('offtaker.project-stage');
    this.responses = _agGridServicesWrapperService._translateService.instant('offtaker.responses');
  }

  public getGridOptions(): GridOptions {
    return <GridOptions>{
      autoGroupColumnDef: {
        headerName: this.column['customer-name']
      },
      columnDefs: this.getColumnSetting(),
      components: {
        agDateInput: DateFilterComponent
      },
      context: {
        currentLang: this.currentLang
      },
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true,
        resizable: true
      },
      enableRangeSelection: true,
      groupDefaultExpanded: 1,
      groupDisplayType: 'singleColumn',
      localeText: this.globalTranslations,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading Customers...</span>',
      rowHeight: 80,
      rowMultiSelectWithClick: true,
      rowSelection: 'multiple',
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['count', 'sum', 'avg'] // options: 'count', 'sum', 'min', 'max', 'avg'
            },
            align: 'right'
          }
        ]
      },
      suppressRowClickSelection: true,
    };
  }

  public getColumnSetting(): any {
    const columnConfigs = this._columnConfigFactory;
    return [
      {
        headerClass: 'checkboxColumn',
        cellClass: 'checkboxCell',
        headerCheckboxSelection: true,
        checkboxSelection: (params) => {
          return params?.data ? true : false;
        },
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        width: 30,
        filter: false,
        resizable: false,
        suppressMenu: true,
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        pinned: 'left'
      },
      {
        headerName: this.column['customer-name'],
        headerTooltip: this.column['customer-name'],
        field: 'offtaker.name',
        width: 300,
        rowGroup: true,
        hide: true,
        unSortIcon: true,
        enableRowGroup: true,
        enableValue: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          const id = params?.node?.allLeafChildren[0]?.data?.offtaker?.id;
          const name = params?.value;
          const encodedName = encodeURIComponent(name);
          if (name) {
            return `<div style="display: flex; flex-direction: column;">
              <a class="hyperlink" href="#/oes/projects/ci-customers/${ id }/overview?bc=${ encodedName }">${ name }</a>
            </div>`;
          }
          return '';
        },
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['total-sites'],
        headerTooltip: this.column['total-sites'],
        field: 'totalProjects',
        width: 150,
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params?.node?.allLeafChildren?.length) {
            const totalSites = params.node.allLeafChildren.reduce((accum, childNode) => {
              const projects = childNode?.data?.totalProjects || 0;
              accum += projects;
              return accum;
            }, 0);
            return `<span style='font-weight: 500;'>${ totalSites } Sites</span>`;
          }
          return params.value;
        },
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['location-of-sites'],
        headerTooltip: this.column['location-of-sites'],
        field: 'country.name',
        width: 300,
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params?.node?.allLeafChildren?.length) {
            const text = params.node.allLeafChildren.reduce((accum, childNode) => {
              const country = childNode?.data?.country?.name;
              if (country && !accum.includes(country)) {
                accum.push(country);
              }
              return accum;
            }, []).sort().join(', ');
            return `<span style='font-weight: 500;'>${ text }</span>`;
          }
          return params.value;
        },
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['stage'],
        headerTooltip: this.column['stage'],
        field: 'stage',
        width: 300,
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params?.node?.allLeafChildren?.length) {
            const textArray = params.node.allLeafChildren.reduce((accum, childNode) => {
              const stage = childNode?.data?.stage;
              if (stage && !accum.includes(stage)) {
                accum.push(stage);
              }
              return accum;
            }, []).sort();
            if (textArray.length > 1) {
              return `<span style='font-weight: 500;'>${ textArray.length } ${ this.stages['plural'] }</span>`;
            } else if (textArray.length === 1) {
              return `<span style='font-weight: 500;'>${ this.stages[textArray[0]] }</span>`;
            }
            return '';
          }
          return this.stages[params.value];
        },
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['grid-configurations'],
        headerTooltip: this.column['grid-configurations'],
        field: 'gridConfiguration',
        width: 300,
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params?.node?.allLeafChildren?.length) {
            const textArray = params.node.allLeafChildren.reduce((accum, childNode) => {
              const gridConfig = childNode?.data?.gridConfiguration;
              gridConfig.forEach(config => {
                if (!accum.includes(config)) {
                  accum.push(config);
                }
              });
              return accum;
            }, []);
            return textArray.map(gridConfigString => {
              return `<span style='font-weight: 500;'>${ this.gridConfigurationTypes[gridConfigString] }</span>`;
            }).sort().join(', ');
          }
          return params.value.map(gridConfigString => {
            return `<span>${ this.gridConfigurationTypes[gridConfigString] }</span>`;
          }).sort().join(', ');
        },
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['currency'],
        headerTooltip: this.column['currency'],
        field: 'currencyType',
        width: 200,
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params?.node?.allLeafChildren?.length) {
            const textArray = params.node.allLeafChildren.reduce((accum, childNode) => {
              const currency = childNode?.data?.currencyType;
              if (currency && !accum.includes(currency)) {
                accum.push(currency);
              }
              return accum;
            }, []).sort();
            if (textArray.length > 1) {
              return `<span style='font-weight: 500;'>${ textArray.length } ${ this.offtaker['overview'].currencies }</span>`;
            } else if (textArray.length === 1) {
              return `<span style='font-weight: 500;'>${ textArray[0] }</span>`;
            }
            return '';
          }
          return params.value;
        },
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
    ];
  }
}
