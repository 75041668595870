<div class="business-plan" [formGroup]="defaultFormGroup">
  <div>
    <!-- <header class="header"> -->
      <!-- <ng-container *ngIf="!json?.components">
        <button
          class="btn btn-primary pull-right"
          type="button"
          (click)="save()"
          [disabled]="defaultFormGroup?.pristine || isPastSubmissionDeadline || this.projectSetPortfolioProgramConnection?.programShareDateTime">
          {{ 'deal-description.save-draft' | translate }}
        </button>
      </ng-container> -->
    <!-- </header> -->
    <section class="deal-description mb-4" *ngIf="dataLoaded">
      <ng-container *ngIf="json?.components; else NoBusinessPlanForm">
        <oes-project-set-portfolio-business-plan-form-custom
          [projectSetPortfolio]="projectSetPortfolio"
          [json]="json"
          [readOnly]="readOnly"
          [isPastSubmissionDeadline]="isPastSubmissionDeadline || this.projectSetPortfolioProgramConnection?.programShareDateTime"
          (customFormAction)="customFormAction($event)">
        </oes-project-set-portfolio-business-plan-form-custom>
      </ng-container>
      <ng-template #NoBusinessPlanForm>
        {{ 'deal-description.no-form' | translate }}
      </ng-template>
    </section>
  </div>
</div>

<oes-modal-dialog
  class="warning-modal"
  [title]="'deal-description.important' | translate"
  [size]="'medium'"
  [activateSubmit]="checkWarningResponse()"
  [submitButtonText]="'buttons.i-understand' | translate"
  [allowCancel]="false"
  [takeUserBack]="true"
  (submit)="saveWarningResponse()">
  <p>{{ 'deal-description.modal-warning' | translate }}</p>
  <input type="text" class="form-control" name="warning-response" [(ngModel)]="warningResponse"/>
</oes-modal-dialog>