import { Component, Input, Output, EventEmitter } from '@angular/core';
import {View} from '@shared/components/view-toggle-button/view.enum';
import { ProjectListType } from '../project-list-type.enum';

@Component({
  selector: 'oes-shared-project-action',
  templateUrl: './shared-project-action.component.html',
  styleUrls: ['./shared-project-action.component.scss']
})
export class SharedProjectActionComponent {
  @Input() selectedProjects = [];
  @Input() listType: ProjectListType = ProjectListType.MAIN;
  @Output() exportCsv: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() changeStageBulk: EventEmitter<boolean> = new EventEmitter<boolean>();
  view = View;

  constructor() {
  }
}
