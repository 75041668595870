import { Injectable } from '@angular/core';
import { GridOptions, GridApi, ColumnApi, ColDef } from 'ag-grid-community';
import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { TitleCasePipe } from '@angular/common';
import { CamelCasePipe } from '@shared/pipes/camel-case.pipe';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { GridState } from '@shared/ag-grid/gird-state';
import { ProjectListType } from '@project/shared-projects-list/project-list-type.enum';
import { User } from '@user/user.model';
import { Program } from '@program/shared/program.model';
import { FinanceConnection } from '@finance/developer-portfolio/shared/finance-connection.model';

const getRendererForStatusValue = (value?: string): string => {
  if (value) {
    value = new TitleCasePipe().transform(value.replace(/_/g, ' '));
    const className = new CamelCasePipe().transform(value);
    return `<span class="bullet ${className}">${value}</span>`;
  }
};

@Injectable({
  providedIn: 'root'
})
export class FmPortfolioListGridSettingService extends BaseAgGridSettingsService {
  _columnApi: ColumnApi;
  _gridApi: GridApi;
  _gridStateStorageKey: string;

  currentGridState: GridState = new GridState();
  defaultGridState: GridState = new GridState();
  initialGridState: GridState = new GridState();

  private messages = {};
  private statuses = {};
  private dropdownStatus = [];

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService) {
    super(_agGridServicesWrapperService);
    _agGridServicesWrapperService._translateService.get('finance-marketplace').subscribe(messages => {
      this.messages = messages;
    });
    _agGridServicesWrapperService._translateService.get('finance-deal-status').subscribe(messages => {
      this.statuses = messages;
      this.dropdownStatus = [
        this.statuses['SUBMITTED'],
        this.statuses['UNDER_REVIEW'],
        this.statuses['SHORT_LISTED'],
        this.statuses['SELECTED'],
        this.statuses['NOT_SELECTED']
      ];
    });
  }

  public getGridOptions(listType: ProjectListType = ProjectListType.MAIN, user: User, program?: Program): GridOptions {
    return <GridOptions>{
      autoGroupColumnDef: {
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          checkbox: true
        }
      },
      columnDefs: this.getColumnSetting(listType, user, program),
      context: {
        currentLang: this.currentLang
      },
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true
      },
      enableRangeSelection: true,
      groupDefaultExpanded: -1,
      groupHideOpenParents: true,
      groupSelectsChildren: true,
      localeText: this.globalTranslations,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading...</span>',
      rowGroupPanelShow: 'always',
      rowHeight: 80,
      rowSelection: 'multiple',
      sideBar: {
        toolPanels: ['columns']
      },
      singleClickEdit: true,
      stopEditingWhenCellsLoseFocus: true,
      suppressColumnMoveAnimation: true,
      suppressDragLeaveHidesColumns: false,
      suppressRowClickSelection: true
    };
  }

  // Column Properties
  public getColumnSetting(listType: ProjectListType, user: User, program?: Program): any {
    const columns = [
      {
        headerName: this.messages['deal-name'],
        headerTooltip: this.messages['deal-name'],
        field: 'name',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        unSortIcon: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          caseSensitive: false
        },
        editable: false,
        suppressMenu: true,
        suppressSizeToFit: true
      },
      {
        headerName: this.messages['organization'],
        field: 'developerOrganization.name',
        headerTooltip: this.messages['organization'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agSetColumnFilter',
        filterParams: {
          clearButton: true,
        },
        editable: false,
        cellClass: 'a-link',
        suppressMenu: true,
        suppressSizeToFit: true
      },
      {
        headerName: this.messages['total-projects'],
        field: 'totalProjects',
        headerTooltip: this.messages['total-projects'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filter: 'agNumberColumnFilter',
        cellClass: 'number',
        editable: false,
        suppressMenu: true,
        filterParams: {
          clearButton: true,
        },
      },
      {
        headerName: this.messages['countries'],
        headerTooltip: this.messages['countries'],
        field: 'countries',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        unSortIcon: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          caseSensitive: false
        },
        editable: false,
        suppressMenu: true,
        suppressSizeToFit: true,
        cellRenderer: params => {
          let regions = '';
          if (params.value) {
            regions = params.value.split(', ').sort().join(', ');
          }
          return regions;
        }
      },
      {
        headerName: this.messages['region'],
        headerTooltip: this.messages['region'],
        field: 'regions',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        unSortIcon: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          caseSensitive: false
        },
        editable: false,
        suppressMenu: true,
        suppressSizeToFit: true,
        cellRenderer: params => {
          let regions = '';
          if (params.value) {
            regions = params.value.split(', ').sort().join(', ');
          }
          return regions;
        }
      },
      {
        headerName: this.messages['total-installed-capacity'],
        field: 'totalCapacity',
        headerTooltip: this.messages['total-installed-capacity'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filter: 'agNumberColumnFilter',
        cellClass: 'number',
        editable: false,
        suppressMenu: true,
        filterParams: {
          clearButton: true,
        },
        cellRenderer: (params) => {
          return Math.round(params.value * 100) / 100;
        }
      },
      {
        headerName: this.messages['total-deal-size'],
        field: 'financeIntroduction.dealSize',
        headerTooltip: this.messages['total-deal-size'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        valueFormatter: (params) => {
          return this.formatCurrency(params, this.currentLang);
        },
        filter: 'agNumberColumnFilter',
        cellClass: 'number',
        editable: false,
        suppressMenu: true,
        filterParams: {
          clearButton: true,
        },
      },
      {
        headerName: this.messages['total-grant'],
        field: 'totalSubsidy',
        headerTooltip: this.messages['total-grant'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        valueFormatter: (params) => {
          return this.formatCurrency(params, this.currentLang);
        },
        filter: 'agNumberColumnFilter',
        cellClass: 'number',
        editable: false,
        suppressMenu: true,
        filterParams: {
          clearButton: true,
        }
      },
      {
        headerName: this.messages['average-grant'],
        // field: 'financeIntroduction',
        headerTooltip: this.messages['average-grant'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        cellRenderer: (params) => {
          if (params.data && params.data.totalSubsidy > 0 && params.data.totalConnections > 0) {
            const average = params.data.totalSubsidy / params.data.totalConnections;
            return this.formatCurrency({value: average}, this.currentLang);
          }
        },
        filter: 'agNumberColumnFilter',
        cellClass: 'number',
        editable: false,
        suppressMenu: true,
        filterParams: {
          clearButton: true,
        }
      },
      {
        headerName: this.messages['submission-date'],
        field: 'currentConnection.acceptDateTime',
        headerTooltip: this.messages['submission-date'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filter: 'agDateColumnFilter',
        editable: false,
        suppressMenu: true,
        filterParams: {
          comparator: this.dateComparator,
          clearButton: true
        },
        cellRenderer: (params) => {
          return this.getLocalDate(params, 'dd MMMM yyyy HH:mm');
        }
      }
    ];

    const programOwnerStatus: any = {
      headerName: this.messages['status'],
      field: 'currentConnection.financeDealStatus',
      headerTooltip: this.messages['status'],
      unSortIcon: true,
      enablePivot: true,
      enableRowGroup: true,
      enableValue: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        selectAllOnMiniFilter: false,
        clearButton: true,
        cellRenderer: (params) => {
          if (params.value) {
            return getRendererForStatusValue(params.value);
          }
        },
        values: this.dropdownStatus
      },
      keyCreator: (params) => {
        return this.statuses[params.value];
      },
      editable: true,
      cellClass: 'status',
      cellRenderer: (params) => {
        if (params.data) {
          const key = params.data.currentConnection?.financeDealStatus;
          return getRendererForStatusValue(key);
        }
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: this.dropdownStatus
      },
      suppressMenu: true
    };

    const programParticipantStatus: any = {
      headerName: this.messages['status'],
      field: 'financeConnections',
      headerTooltip: this.messages['status'],
      unSortIcon: true,
      enablePivot: true,
      enableRowGroup: true,
      enableValue: true,
      filter: false,
      cellClass: 'status',
      cellRenderer: (params) => {
        let conn: FinanceConnection;
        if (params?.value && Array.isArray(params?.value) && params.data?.program?.id) {
          conn = params?.value?.find(
            connection => connection.financeOrganization?.id === params.data.program.sponsor?.id
          );
        } else if (typeof params?.value === 'string') {
          return getRendererForStatusValue(params?.value);
        }
        if (conn) {
          const key = conn.financeDealStatus;
          return getRendererForStatusValue(key);
        }
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: this.dropdownStatus
      },
      editable: true,
      suppressMenu: true
    };

    const contactName: any = {
      headerName: this.messages['contact-name'],
      headerTooltip: this.messages['contact-name'],
      field: 'developerOrganization',
      unSortIcon: true,
      enablePivot: true,
      enableRowGroup: true,
      enableValue: true,
      comparator: this.caseInsensitiveSort,
      filter: 'agSetColumnFilter',
      filterParams: {
        clearButton: true,
      },
      editable: false,
      suppressMenu: true,
      suppressSizeToFit: true,
      autoHeight: true,
      cellRenderer: params => {
        return `${params.data.developerOrganization.contactFirstName} ${params.data.developerOrganization.contactLastName}`;
      },
    };
    const programName: any = {
      headerName: this.messages['program-name'],
      headerTooltip: this.messages['program-name'],
      field: 'program.name',
      enablePivot: true,
      enableRowGroup: true,
      enableValue: true,
      unSortIcon: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        caseSensitive: false
      },
      editable: false,
      suppressMenu: true,
      suppressSizeToFit: true
    };
    const contactEmail: any = {
      headerName: this.messages['contact-email'],
      headerTooltip: this.messages['contact-email'],
      field: 'developerOrganization.contactEmailAddress',
      unSortIcon: true,
      enablePivot: true,
      enableRowGroup: true,
      enableValue: true,
      comparator: this.caseInsensitiveSort,
      filter: 'agSetColumnFilter',
      filterParams: {
        clearButton: true,
      },
      editable: false,
      suppressMenu: true,
      suppressSizeToFit: true,
      autoHeight: true,
      cellRenderer: params => {
        if (params.value) {
          return `<a href="mailto:${params.value}">${params.value}</a>`;
        }
      },
    };

    // portfolio name, org name, program name, org contact name, org contact email

    if (listType === ProjectListType.MAIN) {
      columns.splice(2, 0, programName);
      columns.splice(4, 0, contactName);
      columns.splice(5, 0, contactEmail);
    }

    if (user?.organization?.id === program?.sponsor?.id) {
      columns.splice(2, 0, programOwnerStatus);
    } else {
      columns.splice(2, 0, programParticipantStatus);
    }

    return columns;
  }
}
