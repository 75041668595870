import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { ProjectApiConstants } from './project-api.constant';
import { map, take } from 'rxjs/operators';
import { Project } from './project.model';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { SimpleProject } from './simple-project.model';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { EventService } from '@shared/event.service';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ProjectWithSideEffects } from './project-with-side-effects.model';
import { ProjectType } from './project-type.enum';

/**
 * TODO: working. need to add more endpoints
 */
@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseRestApiService {
  private _programUpdatedSubject = new BehaviorSubject<boolean>(false);
  programUpdated$: Observable<boolean> = this._programUpdatedSubject.asObservable();

  private _projectId: string;
  private _project: Project;

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _eventService: EventService,
              private _translateService: TranslateService,
              private _oauthService: OAuthService) {
    super(_restApiWrapperService);
  }

  set projectId(projectId: string) {
    this._projectId = projectId;
  }

  /**
   *  You should take id from url instead of this, or use a resolver
   */
  get projectId() {
    return this._projectId;
  }

  set project(project: Project) {
    this._project = project;
  }

  get project() {
    return this._project;
  }

  programUpdate() {
    this._programUpdatedSubject.next(true);
  }

  detail(projectId: string): Observable<Project> {
    if (projectId && projectId !== '') {
      return this.get<Project>(ProjectApiConstants.project.detail(projectId))
            .pipe(
              map((project: Project) => {
                return new Project(project);
              })
            );
    }
    return throwError({error: 'No project id'});
  }

  myList(param: any = {page: 0, size: 1000}): Observable<SimpleProject[]> {
    return this.get<SimpleProject[]>(ProjectApiConstants.project.myList(), {params: param})
            .pipe(
              map((projects: SimpleProject[]) => {
                const p = projects.map(project => new SimpleProject(project));
                // TODO: the endpoint doesn't check params. for now, sort here.
                if (param?.sort) {
                  return p.sort((a, b) => {
                    if (a.name > b.name) {
                      return 1;
                    } else if (a.name < b.name) {
                      return -1;
                    } else {
                      return 0;
                    }
                  });
                }
                return p;
              })
            );
  }

  myListByProgram(programId: string): Observable<SimpleProject[]> {
    return this.get<SimpleProject[]>(ProjectApiConstants.project.myListByProgram(programId), {params: {page: 0, size: 1000}})
            .pipe(
              map((projects: SimpleProject[]) => {
                return projects.map(project => new SimpleProject(project));
              })
            );
  }

  sharedList(): Observable<SimpleProject[]> {
    // need size, default is smaller than 1000
    return this.get<SimpleProject[]>(ProjectApiConstants.project.sharedList(), {params: {page: 0, size: 2000}})
            .pipe(
              map((projects: SimpleProject[]) => {
                return projects.map(project => new SimpleProject(project));
              })
            );
  }

  sharedListByProgram(programId: string): Observable<SimpleProject[]> {
    // need size, default is smaller than 1000
    return this.get<SimpleProject[]>(ProjectApiConstants.project.sharedListByProgram(programId), {params: {page: 0, size: 1000}})
            .pipe(
              map((projects: SimpleProject[]) => {
                return projects.map(project => new SimpleProject(project));
              })
            );
  }

  sharedMilestonesByProgram(programId: string): Observable<any> {
    return this.get<SimpleProject[]>(ProjectApiConstants.project.sharedMilestonesByProgram(programId));
  }

  projectMilestoneMap(): Observable<any> {
    return this.get<SimpleProject[]>(ProjectApiConstants.project.projectMilestoneMap());
  }

  sharedProjectMilestoneMap(): Observable<any> {
    return this.get<SimpleProject[]>(ProjectApiConstants.project.sharedProjectMilestoneMap());
  }

  createUpdate(project: Project): Observable<Project> {
    if (project?.id && project.id !== '') {
      return this.update(project);
    }
    return this.post(ProjectApiConstants.project.create(), JSON.stringify(project))
            .pipe(
              map((updatedProject: Project) => new Project(updatedProject))
            );
  }

  private update(project: Project): Observable<Project> {
    return this.put(ProjectApiConstants.project.save(project.id), JSON.stringify(project))
            .pipe(
              map((updatedProject: Project) => new Project(updatedProject))
            );
  }

  updateWithSideEffects(project: Project): Observable<ProjectWithSideEffects> {
    return this.put(ProjectApiConstants.project.updateWithSideEffects(project.id), JSON.stringify(project));
  }

  private createOptions(ids: string[]) {
    return {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this._oauthService.getAccessToken()),
      responseType: 'blob' as const,
      params: new HttpParams().set('projectIds', ids.toString())
    };
  }

  private createPutOptions() {
    return {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this._oauthService.getAccessToken()),
      responseType: 'blob' as const
    };
  }

  downloadFinancialOverviewReport(ids: string []) {
    const body = { projectIds: ids };
    this.put(ProjectApiConstants.project.financialOverview(), JSON.stringify(body), this.createPutOptions())
    .pipe(take(1))
    .subscribe((response: any) => {
      FileSaver.saveAs(response, `Financial Overview Report - ${new Date()}.csv`);
    });
  }

  downloadCustomerSummaryReport(ids: string []) {
    const body = { projectIds: ids };
    this.put(ProjectApiConstants.project.customerSummary(), JSON.stringify(body), this.createPutOptions())
    .pipe(take(1))
    .subscribe((response: any) => {
      FileSaver.saveAs(response, `Customer Summary Report - ${new Date()}.csv`);
    });
  }

  createSampleProject(): Observable<Project> {
    return this.get<Project>(ProjectApiConstants.project.sample())
            .pipe(
              map((project: Project) => new Project(project))
            );
  }

  copy(id: string) {
    return this.post(ProjectApiConstants.project.copy(id))
            .pipe(
              map((project: Project) => new Project(project))
            );
  }

  exportProjectFinancialModelAsync(ids: Array<string>) {
    this.get(ProjectApiConstants.project.exportFinancialModel(), this.createOptions(ids))
    .pipe(take(1))
    .subscribe((response: any) => {
      this._eventService.success(this._translateService.instant('project-list.export-financial-model.message'));
    });
  }

  currencyInfo(): {currency: string, symbol: string} {
    if (this._project.projectType === ProjectType.ci) {
      return {
        currency: this._project.alternateCurrency,
        symbol: 'symbol-narrow'
      };
    } else {
      return {
        currency: 'USD',
        symbol: 'symbol'
      };
    }
  };

  remove(id: string): Observable<any> {
    return this.delete(ProjectApiConstants.project.remove(id));
  }
}
